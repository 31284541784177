import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';
import MyFaqsComponent from '../components/MyFaqsComponent';
import MyComponent from '../components/MyComponent';

function Faq() {
    const [sections, setSection] = useState([]);
    useEffect(() => {
        const fetchSection = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PAGESSECTIONS('4'))
                const parsedData = response.data.map(section => ({
                    ...section,
                    details: Array.isArray(JSON.parse(section.other_details)) ? JSON.parse(section.other_details) : []
                }));
                setSection(parsedData);
                console.log(parsedData)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSection();
    }, [])
    return (
        <>
            <main>
                <section className="breadcrum-section" style={{ backgroundImage: 'url("assets/images/faq-bg.webp")' }}>
                    <div className="container">
                        <h1 className="text-white">FAQ</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/" className="text-white">Home</a></li>
                                <li className="breadcrumb-item active text-white" aria-current="page">FAQ</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {sections.map((section, index) => (
                    <>
                        {section.name === 'Asked Questions' && (

                            <section className="faq-section-intro section-padding">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="image-area">
                                                <img src={API_ENDPOINTS.BASE_URL + section.image} loading="lazy" alt="about image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content-area">
                                                <div className="section-heading">
                                                    <h5>Frequently Asked Questions</h5>
                                                    <h2>{section.headline}</h2>
                                                </div>
                                            </div>
                                            <div className="accordion accordion-flush" id="accordionFlushExample">

                                                {section.details.map((question, indexQuestion) => (
                                                    <div className="accordion-item" key={indexQuestion}>
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#flush-collapseOne_${indexQuestion}`}
                                                                aria-expanded="false"
                                                                aria-controls={`flush-collapseOne_${indexQuestion}`}>
                                                                <span>0{indexQuestion + 1}</span> {question.text}
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapseOne_${indexQuestion}`}
                                                            className="accordion-collapse collapse"
                                                            data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                {question.textarea}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        )}

                        {section.name === 'faq' && (
                            <section className="faq-main section-padding">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-md-8">
                                            <div className="section-heading text-center">
                                                <h5>FAQ</h5>
                                                <h2 className="text-white">{section.headline}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-faq">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10">
                                                <div className="accordion accordion-flush" id="mainfaq">
                                                   <MyFaqsComponent />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                        {section.name === 'still' && (
                            <section className="cta-section section-padding">
                    <div className="container">
                        <div className="inner">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-10">
                                    <div className="wrapper">
                                        <h3>{section.headline}</h3>
                                        <p>
                                            <MyComponent content={section.description} />
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-2 text-end">
                                    <a href="#url" className="button-style1">Read more <i
                                        className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                        )}

                    </>
                ))}


                
            </main>
        </>
    )
}

export default Faq