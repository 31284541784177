import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';

function ReviewComponent() {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {

        const fetchReview = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.REVIEWS);
                setReviews(response.data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchReview();

    })

    return (
        <>
            <div className="testimonial-slider swiper-container">
                <div className="swiper-wrapper">
                    {reviews.map((review, indexReview) => (
                        <div className="swiper-slide">
                            <p>{review.description}</p>
                            <div className="info">
                                <h5>{review.client_name}</h5>
                                <p>{review.designation}</p>
                            </div>
                        </div>
                    ))}
                    
                    
                </div>
            </div>
            <div className="swiper-pagination">
            {reviews.map((pagination, indexPagination) => (
                <span className="swiper-pagination-bullet"
                    style={{ backgroundImage: `url('${API_ENDPOINTS.BASE_URL+pagination.image}')` }}></span>
            ))}
                
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
        </>
    )
}

export default ReviewComponent