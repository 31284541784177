import axios from 'axios';
import React, { useState, useEffect } from 'react';
import API_ENDPOINTS from '../config/apiConfig';
import { useNavigate } from 'react-router-dom';
 // Moved import to the top for clarity

function Login() {
    const navigate = useNavigate();
    useEffect(() =>{
        import('./style.css');
        const token = localStorage.getItem('authenticatetoken');
        if (token) {
            navigate('/about-your-self');
        }
    },[navigate]);
    const [pass, setPass] = useState("password");
    const [icon, setIcon] = useState('fa-eye-slash');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ username: '', password: '' });
    const [formValue, setFormValue] = useState({
        username: '',
        password: ''
    });
    const [mainError, setMainError] = useState({ class: '', text: '' });

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const togglePasswordVisibility = () => {
        if (pass === 'text') {
            setPass('password');
            setIcon('fa-eye-slash');
        } else {
            setPass('text');
            setIcon('fa-eye');
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setFormValue((prev) => ({ ...prev, password: newPassword }));

        // Validate the password as the user types
        if (!passwordRegex.test(newPassword)) {
            setErrors((prev) => ({ ...prev, password: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a digit, and a special character.' }));
        } else {
            setErrors((prev) => ({ ...prev, password: '' }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        let hasError = false;
        const newErrors = { username: '', password: '' };

        if (!formValue.username) {
            newErrors.username = 'This field is required';
            hasError = true;
        }

        if (!formValue.password) {
            newErrors.password = 'This field is required';
            hasError = true;
        } else if (!passwordRegex.test(formValue.password)) {
            newErrors.password = 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a digit, and a special character.';
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('username', formValue.username);
        formData.append('password', formValue.password);

        try {
            const response = await axios.post(API_ENDPOINTS.LOGIN, formData ,{
               headers: {
                        'Content-Type': 'application/json',
                    },
            })
            if(response.status === 200){
                localStorage.setItem('authenticatetoken', response.data.token);
                localStorage.setItem('authenticateid', response.data.customer_id);
                navigate('/about-your-self');
            }else{
                setMainError({
                    class:'alert alert-danger',
                    text: response.data.error
                })
                
            }
        } catch (error) {
            // Ensure error.message is used to get a string representation of the error
            setMainError({
                class: 'alert alert-danger',
                text: error.message || 'An unexpected error occurred',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <section className="join-section">
                <div className="wrapper">
                    <div className="main-area">
                        <div className="logo">
                            <a href="#" onClick={() => navigate('/register')}>
                                <img src="assets/images/logo.webp" alt="Logo" />
                            </a>
                        </div>
                        <h4>Welcome Back!</h4>
                        <p>Log in to willbylawyers to continue</p>
                        {mainError.text && <div className={mainError.class}>{mainError.text}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="input-wrapper">
                                <div className="form-floating">
                                    <input
                                        type="email"
                                        className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                        id="floatingInput"
                                        placeholder="Email address"
                                        name="username"
                                        value={formValue.username}
                                        onChange={(e) => setFormValue((prev) => ({ ...prev, username: e.target.value }))}
                                    />
                                    <label htmlFor="floatingInput">Email address</label>
                                    {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                                </div>
                            </div>
                            <div className="input-wrapper">
                                <div className="form-floating position-relative">
                                    <input
                                        type={pass}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        id="floatingPassword"
                                        placeholder="Password"
                                        name="password"
                                        value={formValue.password}
                                        onChange={handlePasswordChange}
                                    />
                                    <label htmlFor="floatingPassword">Password</label>
                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    <span className="position-absolute top-50 end-0 translate-middle-y me-3" onClick={togglePasswordVisibility}>
                                        <i className={`fas ${icon}`}></i>
                                    </span>
                                </div>
                            </div>
                            <div className="input-wrapper">
                                <button type="submit" className="button-style1 px-5" disabled={loading}>
                                    {loading ? 'Wiating...' : 'Login'}
                                </button>
                            </div>
                            <p>Don't have an account? <a href="#" onClick={() => navigate('/register')} className="text-sky">Sign-up</a></p>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
