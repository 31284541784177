import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../../../config/apiConfig';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';

function Message() {
    const [messageData, setMessageData] = useState({
        lastMessage: ''
    });
    const [mainid, setMainId] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.PARSONALDETAILS(localStorage.getItem('authenticateid')));
                if(response.status === 200){
                    const data = response.data;
                    console.log(data);
                    if(data !== ''){
                      setMainId(data.id);
                      if(data.last_message !== '' && data.last_message !== null){
                        setMessageData({
                            lastMessage: data.last_message
                        })
                      }
                    }
          
                   
                  }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[]);

    const finalSubmit = async (event) =>{
        event.preventDefault();
        
        if(messageData.lastMessage){
            const formData = new FormData();
            if(mainid){
                formData.append('id', mainid);
              }
            formData.append('customer_id', localStorage.getItem('authenticateid'));
            formData.append('last_message', messageData.lastMessage);
            console.log(Object.fromEntries(formData));
            try {
                const response = await axios.post(API_ENDPOINTS.PARSONALACTION, formData, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                if (response.status === 200) {
                  
                  alert({
                    text: response.data,
                    type: 'success',
                  });
                  navigate('/pricing-liabilities')
                } else {
                  alert({
                    text: response.data,
                    type: 'error',
                  });
                }
                
              } catch (error) {
                alert({
                  text: error,
                  type: 'error',
                });
              }
        }
    }

  return (
    <>
                    <form action="" id="last-message"  onSubmit={finalSubmit}>
                        <h2>Any Last Message</h2>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-wrapper">
                                    <label for="" className="form-label">Any last message</label>
                                    <textarea name="lastMessage"  id="" placeholder='Any Last Message' className="form-control" defaultValue={messageData.lastMessage} onChange={(e) => setMessageData((prev) => ({...prev,lastMessage:e.target.value}))} />
                                </div>
                            </div>
                        </div>
                        <div>
                        <button type='submit' className="button-style3">Next</button>
                        <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/assets-liabilities')}>Back</span>
                        </div>
                    </form>
    </>
  )
}

export default Message