import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import Faq from './pages/Faq';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/form/page/dashboard';
import Comman from './components/form/Comman';
import Executors from './components/form/page/Executors';
import Estate from './components/form/page/Estate';
import Liabilities from './components/form/page/Liabilities';
import Message from './components/form/page/Message';
import Pets from './components/form/page/Pets';
import Residual from './components/form/page/Residual';
import AssetsLiabilities from './components/form/page/AssetsLiabilities';
import PricingForm from './components/form/page/Pricing';
import Register from './components/Register';
import Login from './components/Login';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<Faq />} />
        <Route path='/register' element={<Register />}></Route>
        <Route path='/login' element={<Login />}></Route>

        {/* Nested Routes within the Comman component */}
        <Route
          path="*"
          element={
            <Comman>
              <Routes>
                <Route path="/about-your-self" element={<Dashboard />} />
                <Route path="/executors-details" element={<Executors />} />
                <Route path="/estate" element={<Estate />} />
                <Route path="/liabilities" element={<Liabilities />} />
                <Route path="/message" element={<Message />} />
                <Route path="/pets" element={<Pets />} />
                <Route path="/residual" element={<Residual />} />
                <Route path="/assets-liabilities" element={<AssetsLiabilities />} />
                <Route path="/pricing-liabilities" element={<PricingForm />} />
                
              </Routes>
            </Comman>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
