import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import './dashboard.css';
function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
  

    const goTo = (page) => {
        navigate(page);
    }

    const isActive = (path) => location.pathname === path ? 'active' : '';
    return (
        <>
            <div className="sidebar-area">
                <h3 className="primary-text"><img src="assets/images/dashboard-icon.png" alt="" /> Dashboard</h3>
                <aside>
                    <ul>
                        <li className={isActive('/about-your-self')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/about-your-self')}>
                                About yourself
                            </a>
                            <span>
                                <i className="fa-solid fa-user"></i>
                            </span>
                        </li>
                        <li className={isActive('/executors-details')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/executors-details')}>
                                Executors
                            </a>
                            <span>
                                <i className="fa-solid fa-people-arrows"></i>
                            </span>
                        </li>
                        <li className={isActive('/estate')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/estate')}>
                                Estate And Gift
                            </a>
                            <span>
                                <i className="fa-solid fa-gift"></i>
                            </span>
                        </li>
                        <li className={isActive('/residual')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/residual')}>
                                Share of Residual Estate
                            </a>
                            <span>
                                <i className="fa-solid fa-sign-hanging"></i>
                            </span>
                        </li>
                        <li className={isActive('/pets')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/pets')}>
                                Pets Details
                            </a>
                            <span>
                                <i className="fa-solid fa-dog"></i>
                            </span>
                        </li>
                        <li className={isActive('/assets-liabilities')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/assets-liabilities')}>
                                Add Assets and Liabilities
                            </a>
                            <span>
                                <i className="fa-solid fa-house-chimney"></i>
                            </span>
                        </li>
                        <li className={isActive('/message')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/message')}>
                                Any Last Message
                            </a>
                            <span>
                                <i className="fa-solid fa-message"></i>
                            </span>
                        </li>
                        <li className={isActive('/pricing-liabilities')}>
                            <a href="javascript:void(0)" onClick={() => goTo('/pricing-liabilities')}>
                                Choose your pricing plan
                            </a>
                            <span>
                                <i className="fa-solid fa-tag"></i>
                            </span>
                        </li>
                    </ul>
                </aside>
                <div className="line"></div>
            </div>
        </>
    )
}

export default Sidebar