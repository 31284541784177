import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../../../config/apiConfig';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
const Executors = () => {
    const [isCheck, setIsCheck] = useState(false);
    const [readio, setRedio] = useState('yes');
    const [memberid, setMemberid] = useState('');
    const [members, setMembers] = useState([]);
    const [status, setStatus] = useState('initial');
    const [customerid, setCustomerId] = useState('')
    const [modalform, setModalForm] = useState({
        firstName: '',
        lastName: '',
        midName: '',
        reation: ''
    })

    const [modalError, setModalError] = useState({
        firstName: '',
        lastName: '',
        reation: ''
    })
    const [colorMapping, setColorMapping] = useState({
        A: '#F0F8FF', // AliceBlue
        B: '#F5F5DC', // Beige
        C: '#FFF8DC', // Cornsilk
        D: '#E9967A', // DarkSalmon
        E: '#F0EAD6', // Eggshell
        F: '#FFFAF0', // FloralWhite
        G: '#DCDCDC', // Gainsboro
        H: '#F0FFF0', // Honeydew
        I: '#FFFFF0', // Ivory
        J: '#F8DE7E', // Jasmine
        K: '#F0E68C', // Khaki
        L: '#E6E6FA', // Lavender
        M: '#F5FFFA', // MintCream
        N: '#FFDEAD', // NavajoWhite
        O: '#FDF5E6', // OldLace
        P: '#FFDAB9', // PeachPuff
        Q: '#E8D7A1', // Quartz (custom color)
        R: '#BC8F8F', // RosyBrown
        S: '#FFF5EE', // Seashell
        T: '#D8BFD8', // Thistle
        U: '#FFDDCA', // UnbleachedSilk (custom color)
        V: '#F3E5AB', // Vanilla
        W: '#F5F5F5', // WhiteSmoke
        X: '#738678', // Xanadu (Note: Xanadu is not a light color, but this is the closest match)
        Y: '#9ACD32', // YellowGreen
        Z: '#E5E5E5', // ZincWhite (custom color) // Custom name, as there's no Z color in standard CSS
    });

    const [formValue, setFormValue] = useState({
        executor: false,
        firstName: '',
        midName: '',
        lastName: '',
        address: '',
        relation: ''
    })
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        address: '',
        relation: ''
    })
    useEffect(() => {
        const fetchMainDetails = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.EXECUTORDETAILS(localStorage.getItem('authenticateid')));
                if(response.status === 200){
                    const data = response.data;
                    setCustomerId(data.id)
                    setFormValue({
                        executor: data.spouse === 'yes' ? true : false,
                        firstName: data.first_name,
                        midName: data.middle_name,
                        lastName: data.last_name,
                        address: data.primary_executor_address,
                        relation: data.primary_executor_relation
                    });
                    setIsCheck(data.someone_else == 'yes' ? true : false)
                    setRedio(data.substitute_executor)
                }
            } catch (error) {
                console.log(error)
            }
        }
        const fetchSubsititute = async () => {
            try {
                const res = await axios.get(API_ENDPOINTS.SUBSTITUTEDATA(localStorage.getItem('authenticateid')));
                setMembers(res.data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchMainDetails();
        fetchSubsititute();
    }, [status])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalForm((prevFamily) => ({
            ...prevFamily,
            [name]: value,
        }));

    };

    const handleMainInputChange = (event) =>{
        const { name, value } = event.target;
        setFormValue((prevFamily) => ({
            ...prevFamily,
            [name]: value,
        }));
    }

    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setIsCheck(!isCheck);
    }
    const headleExecutor = (event) => {
        setRedio(event.target.value)
    }

    const handleAddsubstitute = async () => {
        let hasError = false;
        const newErrors = {
            firstName: '',
            lastName: '',
            reation: ''
        }
        if (!modalform.firstName) {
            newErrors.firstName = 'This field is required';
            hasError = true;
        }
        if (!modalform.lastName) {
            newErrors.lastName = 'This field is required';
            hasError = true;
        }
        if (!modalform.reation) {
            newErrors.reation = 'This field is required';
            hasError = true;
        }

        if (hasError) {
            setModalError(newErrors)
        } else {
            const formData = new FormData();
            if(memberid !== ''){
                formData.append('id', memberid);
            }
            formData.append('customer_id', localStorage.getItem('authenticateid'));
            formData.append('first_name', modalform.firstName);
            formData.append('middle_name', modalform.midName);
            formData.append('last_name', modalform.lastName);
            formData.append('relation', modalform.reation);

            try {
                const response = await axios.post(API_ENDPOINTS.SUBSTITUTEACTION, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 200) {
                    document.getElementById('close-modal-button').click();
                    setStatus(prev => !prev);

                    alert({
                        text: response.data,
                        type: 'success',
                    });
                    if(memberid !== ''){
                        setMemberid('');
                    }
                    setModalForm({
                        firstName: '',
                        lastName: '',
                        midName: '',
                        reation: ''
                    })
                } else {
                    alert({
                        text: response.data,
                        type: 'error',
                    });
                }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                });
            }

        }

    }

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this SUBSTITUTE')) {
            try {
                const res = await axios.get(API_ENDPOINTS.SUBSTITUTEDELETE(id));
                if (res.status === 200) {
                    setStatus(prev => !prev);
                    alert({
                        text: res.data.message,
                        type: 'success',
                    });
                } else {
                    alert({
                        text: res.data.error,
                        type: 'error',
                    });
                }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                });
            }
        }

    }

    const handleEdit = async (id) => {
        document.getElementById('open-modal-button').click();
        try {
            const response = await axios.get(API_ENDPOINTS.SUBSTITUTEDETAILS(id));
            const data = response.data;
            setMemberid(data.id)
            setModalForm({
                firstName: data.first_name,
                lastName: data.last_name,
                midName: data.middle_name,
                reation: data.relation
            })


        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModal = () => {
        setMemberid('')
        setModalForm({
            firstName: '',
            lastName: '',
            midName: '',
            reation: ''
        })
      }

    const handleSubmitMainForm = async (event) =>{
        event.preventDefault();
        let hasError = false;
        const newErrors = {
            firstName: '',
            lastName: '',
            address: '',
            relation: ''
        }
        if(isCheck){
            if(!formValue.firstName){
                newErrors.firstName = 'This field is required';
                hasError = true;
            }
            if(!formValue.lastName){
                newErrors.lastName = 'This field is required';
                hasError = true;
            }
            if(!formValue.address){
                newErrors.address = 'This field is required';
                hasError = true;
            }
            if(!formValue.relation){
                newErrors.relation = 'This field is required';
                hasError = true;
            }
        }
        if(hasError){
            setErrors(newErrors);
        }else{
            const formData = new FormData();
            if(customerid){
                formData.append('id', customerid);
              }
            formData.append('customer_id', localStorage.getItem('authenticateid'));
            formData.append('spouse', formValue.executor ? 'yes' : 'no');
            formData.append('someone_else', isCheck ? 'yes' : 'no');
            formData.append('first_name', formValue.firstName);
            formData.append('middle_name', formValue.midName);
            formData.append('last_name', formValue.lastName);
            formData.append('primary_executor_address', formValue.address);
            formData.append('primary_executor_relation', formValue.relation);
            formData.append('substitute_executor', readio);
            try {
                const response = await axios.post(API_ENDPOINTS.EXECUTORACTION, formData, {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
                  if (response.status === 200) {
                    
                    alert({
                      text: response.data,
                      type: 'success',
                    });
                    navigate('/estate')
                  } else {
                    alert({
                      text: response.data,
                      type: 'error',
                    });
                  }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                  });
            }



        }
        
    }
    return (
        <>
            <form action="" id="executors-details" onSubmit={handleSubmitMainForm}>
                <h2>Executors Details</h2>
                <p>You can appoint Multiple Primary executors who will be acting togeter ”An executor is a
                    person who has been nominated by Will to finalise a person’s personal, financial, and
                    legal affairs after their death.</p>
                <p>This includes managing and distributing the assets of the estate” The executor can be a
                    partner, spouse, lawyer, trustee or the person’s adult children.</p>
                <p>It’s imperative to choose someone you trust as the executor of your estate. </p>
                <h5 className="text-black">Choose primary executor</h5>
                <div className="row">
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="executor" value={formValue.executor} id="executor" onChange={handleMainInputChange} />
                            <label className="form-check-label tilted-text" for="executor">Spouse (most
                                preferred option) ( For single , please choose below option)</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="executor1" name="executor1" checked={isCheck} onChange={handleCheckboxChange} />
                            <label className="form-check-label tilted-text" for="executor1">Someone else</label>
                        </div>
                        {isCheck && (
                            <div className="row">
                                <div className="col-12">
                                    <label for="">Primary Executor Full Name*</label>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" placeholder="First"  name='firstName' value={formValue.firstName} onChange={handleMainInputChange} />
                                        {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" placeholder="Middle" name='midName' value={formValue.midName} onChange={handleMainInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" placeholder="Last" name='lastName' value={formValue.lastName} onChange={handleMainInputChange} />
                                        {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-wrapper">
                                        <label for="">Primary Executor Address *</label>
                                        <input type="text" className="form-control" placeholder="" name='address' value={formValue.address} onChange={handleMainInputChange} />
                                        {errors.address && <p className="text-danger">{errors.address}</p>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-wrapper">
                                        <label for="">Primary Executor Relationship *</label>
                                        <input type="text" className="form-control" placeholder="" name='relation' value={formValue.relation} onChange={handleMainInputChange} />
                                        {errors.relation && <p className="text-danger">{errors.relation}</p>}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="input-wrapper">
                            <label for="">Do you wish to add an Substitute Executor? *</label>
                            <div>
                                <input type="radio" id="radio-9" name="executor" value="yes" checked={readio === 'yes'} onChange={headleExecutor} />
                                <label className="tab" for="radio-9">Yes</label>
                                <input type="radio" id="radio-10" name="executor" value="no" checked={readio === 'no'} onChange={headleExecutor} />
                                <label className="tab" for="radio-10">No</label>
                            </div>
                        </div>
                        {readio === 'yes' && (
                            <>
                                {members.length > 0 && (
                                    <>
                                        <h5>My Substitute Executor...</h5>

                                        {members.map((member, index) => (
                                            <div key={member.id} style={{ backgroundColor: 'white', padding: '10px', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }} className="d-flex p-3 border rounded">

                                                <div className="rounded-circle d-flex justify-content-center" style={{ backgroundColor: colorMapping[member.first_name[0].toUpperCase()], width: '50px', height: '50px', padding: '10px' }}>{member.first_name[0].toUpperCase()}</div>

                                                <div>{member.first_name + " " + member.middle_name + " " + member.last_name} <br />
                                                    <button type="button" style={{ paddingLeft: '4px', paddingRight: '4px', border: 'none', borderRadius: '2px', fontSize: '10px' }}>{member.relation}</button>
                                                </div>
                                                <div><span onClick={() => handleDelete(member.id)} style={{ cursor: 'pointer' }}><i class="fa-solid fa-circle-minus"></i> Remove</span></div>
                                                <div><span style={{ cursor: 'pointer' }} onClick={() => handleEdit(member.id)}><i class="fa-solid fa-pen"></i> Edit</span></div>
                                            </div>
                                        ))}

                                    </>
                                )}
                                {members.length < 2 && (
                                <div className="new-child">
                                    <div className="add-child">
                                        <p className="mb-0">None Selected</p>
                                        <a href="javascript:void(0)" className="button-style1" data-bs-toggle="modal"
                                            data-bs-target="#add-substitute" id="open-modal-button" onClick={handleOpenModal}><i className="fa-solid fa-plus"></i>Add
                                            Substitute Executor</a>
                                    </div>

                                    {/* <!-- Modal --> */}
                                    <div className="modal fade" id="add-substitute" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Add
                                                        Substitute Executor
                                                    </h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close" id="close-modal-button"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <h5>Substitute Executor Full Name</h5>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-wrapper">
                                                                <input type="text" placeholder="First Name"
                                                                    className="form-control" name="firstName" value={modalform.firstName} onChange={handleInputChange} />
                                                                    {modalError.firstName && <p className="text-danger">{modalError.firstName}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-wrapper">
                                                                <input type="text" placeholder="Middle Name"
                                                                    className="form-control" name="midName" value={modalform.midName} onChange={handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="input-wrapper">
                                                                <input type="text" placeholder="Last Name"
                                                                    className="form-control" name="lastName" value={modalform.lastName} onChange={handleInputChange} />
                                                                    {modalError.lastName && <p className="text-danger">{modalError.lastName}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5>Relationship </h5>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="input-wrapper">
                                                                <input type="text" className="form-control" name="reation" value={modalform.reation} onChange={handleInputChange} />
                                                                {modalError.reation && <p className="text-danger">{modalError.reation}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type='button' className="button-style1" onClick={handleAddsubstitute}>Add</button>
                                                    {/* <button type="button" className="button-style1" ata-bs-dismiss="modal"
                                                        aria-label="Close">Cancel</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                )}
                            </>
                        )}

                    </div>
                    <div className="col-12">
                        <div className="input-wrapper">
                            <p>A substitute executor is a person named in the Will that will take over the
                                role of executor if the executor is unwilling or unable to act. An executor
                                may be unable to perform their duties for a number of reasons</p>
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit"  className="button-style3">Next</button>
                    <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/about-your-self')}>Back</span>
                </div>
            </form>
        </>
    );
}

export default Executors;
