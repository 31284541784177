import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import API_ENDPOINTS from '../config/apiConfig';
import TimeDisplay from './TimeDisplay';

function Header() {
   const navigate = useNavigate();
   const [website, setWebsite] = useState([]);
   useEffect(() =>{
        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.GETWEBSITEDETAIL('1'))
                setWebsite(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
   },[])

   const pageChange = (page) => {
    navigate(page);
   }
  return (
    <>
    <header className="header">
            <div className="top-header">
                <div className="container">
                    <div className="top-header-wrapper">
                        <p className="text-white">Our No Fee Promise Means, No Cost Until Your Case is Won.</p>
                        <ul className="social-media">
                            <li><a href={website.facebook}><i className="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href={website.instagram}><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a href={website.twitter}><i className="fa-brands fa-x-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="middle-header">
                <div className="container">
                    <div className="middle-header-wrapper">
                        <address>
                            <p>{website.address}</p>
                            <a href={`tel:${website.contact}`} className="link">{website.contact}</a>
                        </address>
                        <div className="logo-area">
                            <a onClick={() => pageChange('/')}>
                            <img src={API_ENDPOINTS.BASE_URL+website.logo} alt="willbylawyers logo"/>
                            </a>
                        </div>
                        <div className="contact-info">
                            <p>Office Hours: <TimeDisplay timeFromDatabase={website.open} /> - <TimeDisplay timeFromDatabase={website.close} /></p>
                            <a href="mailto:info@willbylawyers.com" className="link">info@willbylawyers.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-header">
                <div className="container">
                    <div className="menu-header-wrapper">
                        <nav>
                            <ul>
                                <li><a  onClick={() => pageChange('/')} className="active" style={{cursor: "pointer"}}>home</a></li>
                                <li><a  onClick={() => pageChange('/about')} className="active" style={{cursor: "pointer"}}>About us</a></li>
                                <li><a  onClick={() => pageChange('/pricing')} className="active" style={{cursor: "pointer"}}>pricing</a></li>
                                <li><a  onClick={() => pageChange('/faq')} className="active" style={{cursor: "pointer"}}>faq</a></li>
                                <li><a  onClick={() => pageChange('/contact')} className="active" style={{cursor: "pointer"}}>Contact</a></li>
                            </ul>
                        </nav>
                        <a onClick={() => pageChange('/login')} className="button-style2">make my will</a>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Header