import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import axios from 'axios';
import API_ENDPOINTS from '../../../config/apiConfig';

function AssetsLiabilities() {
    const navigate = useNavigate();
    const [radio, setRadio] = useState('no');
    const [mainid, setMainid] = useState('');
    const [isCheck, setIsCheck] = useState({
        realEstate: false,
        bank: false,
        share: false,
        bonds: false,
        superAnnuation: false,
        family: false,
        personal: false,
        details: false
    });
    const [selectedValue, setSelectedValue] = useState('');
    const [fields, setFields] = useState([
        { selectValue: '', textareaValue: '' }
    ]);
    const options = ['Sole Ownership', 'Joint ownership', 'Tenant in common'];
    const [formvalue, setFormvalue] = useState({
        bankDetails: '',
        shareDetails: '',
        bondsDetails: '',
        policy: '',
        liabilitiesDetails: '',
        deathBenefitDetails: 'yes',
        donate: '',
        familyCompanyDetails: '',
        personalDetails: '',
        funeralDetails: ''

    })
    const [errors, setErrors] = useState({
        bankDetails: '',
            shareDetails: '',
            bondsDetails: '',
            policy: '',
            liabilitiesDetails: '',
            deathBenefitDetails: '',
            donate: '',
            familyCompanyDetails: '',
            personalDetails: '',
            funeralDetails: ''
    })
    const [benefitradio, setBenefitradio] = useState('no')
    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.LIABILITIESDETAILS(localStorage.getItem('authenticateid')))
                
                if(response.status === 200){
                    const data = response.data;
                    if(data !== ''){
                        console.log(response.data)
                        setMainid(data.id)
                        setRadio(data.liabilities_details_option)
                        setIsCheck({
                            realEstate: data.realestate_option === 'yes' ? true : false,
                            bank: data.bank_option === 'yes' ? true : false,
                            share: data.share_option === 'yes' ? true : false,
                            bonds: data.bonds_option === 'yes' ? true : false,
                            superAnnuation: data.superannuation_option === 'yes' ? true : false,
                            family: data.family_option === 'yes' ? true : false,
                            personal: data.personal_option === 'yes' ? true : false,
                            details: data.details_of_liabilities_option === 'yes' ? true : false,
                        })
                        setSelectedValue(data.realestate_number)
                        setFields(Array.isArray(JSON.parse(data.ownership_types)) ? JSON.parse(data.ownership_types) : [])
                        setFormvalue({
                            bankDetails: data.bank_details,
                            shareDetails: data.share_details,
                            bondsDetails: data.bonds_details,
                            policy: data.superannuation_details,
                            liabilitiesDetails: data.superannuation_liabilities_details,
                            donate: data.superannuation_donate_organs,
                            familyCompanyDetails: data.family_trusts_details,
                            personalDetails: data.family_specific_bequests,
                            funeralDetails: data.funeral_details
                    
                        })
                        setBenefitradio(data.superannuation_death_benefit_nomination)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    },[]);
    useEffect(() => {
       
        const newFields = Array.from({ length: selectedValue }, () => ({
            selectValue: '',
            textareaValue: ''
        }));
        if (fields.length < selectedValue) {
            setFields([...fields, ...newFields.slice(0, selectedValue - fields.length)]);
        } else if (fields.length > selectedValue) {
            setFields(fields.slice(0, selectedValue));
        }


    },[selectedValue])
    // Function to add fields based on selected value
  // Function to handle changes in the dynamic fields
  const handleFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, [name]: value } : field
    );
    setFields(updatedFields);
  };

  // Function to remove a field
    const removeField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const handleToggle = (event) => {
        // console.log(event.target)
        const { id, checked } = event.target;

        setIsCheck((prevState) => ({
            ...prevState,
            [id]: checked,
        }));

    };
    const headle = (event) => {
        setRadio(event.target.value);
    }
    const handlebenefitChange = (event) =>{
        setBenefitradio(event.target.value)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormvalue((prevFamily) => ({
          ...prevFamily,
          [name]: value,
        }));
    
      };

    const handleFinalSubmit = async (event) =>{
        event.preventDefault();
        let hasError = false;
        const newErrors = {
            bankDetails: '',
            shareDetails: '',
            bondsDetails: '',
            policy: '',
            liabilitiesDetails: '',
            deathBenefitDetails: '',
            donate: '',
            familyCompanyDetails: '',
            personalDetails: '',
            funeralDetails: ''
        }
        if(radio === 'yes'){
            if(isCheck.bank){
                if(!formvalue.bankDetails){
                    newErrors.bankDetails = 'This field is required';
                    hasError = true;
                }
            }

                if(isCheck.share){
                    if(!formvalue.shareDetails){
                        newErrors.shareDetails = 'This field is required';
                        hasError = true;
                    }
                }
                if(isCheck.bonds){
                    if(!formvalue.bondsDetails){
                        newErrors.bondsDetails = 'This field is required';
                        hasError = true;
                    }
                }
                if(isCheck.superAnnuation){
                    if(!formvalue.policy){
                        newErrors.policy = 'This field is required';
                        hasError = true;
                    }

                   
                }
                if(isCheck.family){
                    if(!formvalue.familyCompanyDetails){
                        newErrors.familyCompanyDetails = 'This field is required';
                        hasError = true;
                    }
                    if(!formvalue.personalDetails){
                        newErrors.personalDetails = 'This field is required';
                        hasError = true;
                    }
                }
            // if(formvalue.funeralDetails){
            //     newErrors.funeralDetails = 'This field is required';
            //     hasError = true;
            // }
            }
            if(hasError){
                setErrors(newErrors)
            }else{
                const formData = new FormData();
               
                if(mainid){
                    formData.append('id', mainid);
                  }
                // console.log(fields);
                formData.append('customer_id', localStorage.getItem('authenticateid'));
                formData.append('liabilities_details_option', radio);
                formData.append('realestate_option', isCheck.realEstate ? "yes" : 'no');
                formData.append('bank_option', isCheck.bank ? 'yes' : 'no');
                formData.append('share_option', isCheck.share ? 'yes' : 'no');
                formData.append('bonds_option', isCheck.bonds ? 'yes' : 'no');
                formData.append('superannuation_option', isCheck.superAnnuation ? 'yes' : 'no');
                formData.append('family_option', isCheck.family ? 'yes' : 'no');
                formData.append('personal_option', isCheck.personal ? 'yes' : 'no');
                formData.append('details_of_liabilities_option', isCheck.details ? 'yes' : 'no');
                formData.append('realestate_number', selectedValue);
                formData.append('ownership_types',JSON.stringify(fields));
                // formData.append('addresses',JSON.stringify(fields.textareaValue));
                formData.append('bank_details', formvalue.bankDetails);
                formData.append('share_details', formvalue.shareDetails);
                formData.append('bonds_details', formvalue.bondsDetails);
                formData.append('superannuation_details', formvalue.policy);
                formData.append('superannuation_liabilities_details', formvalue.liabilitiesDetails);
                formData.append('superannuation_death_benefit_nomination', benefitradio);
                formData.append('family_trusts_details', formvalue.familyCompanyDetails);
                formData.append('family_specific_bequests', formvalue.personalDetails);
                formData.append('funeral_details', formvalue.funeralDetails);
                console.log(Object.fromEntries(formData.entries()));
                try {
                    const response = await axios.post(API_ENDPOINTS.LIABILITIESACTION , formData, {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                    })
                    if(response.status === 200){
                        alert({
                            text: response.data,
                            type: 'success',
                          });
                          navigate('/message')
                    }else{
                        alert({
                            text: response.data,
                            type: 'error',
                          });
                    }
                    
                } catch (error) {
                    alert({
                        text: error,
                        type: 'error',
                      });
                }
                
                
            }

        // }else{
        //     navigate('/message')
        // }
        
    }

    return (
        <>
            <form action="" id="assets-liabilities" onSubmit={handleFinalSubmit}>
                <h2>Add Assets and Liabilities</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="input-wrapper">
                            <label for="">Do you wish to provide your assets and liabilities details? Note
                                that the Assets and Liabilities will not be a part of your Will. Instead, it
                                will be included as an annexure. This will help your executor to know your
                                assets and liabilities.</label>
                            <div>
                                <input type="radio" id="radio-24" name="assets-liabilities-details"
                                    value="yes" checked={radio === 'yes'} onChange={headle} />
                                <label className="tab" for="radio-24">Yes</label>
                                <input type="radio" id="radio-25" name="assets-liabilities-details"
                                    value="no" checked={radio === 'no'} onChange={headle} />
                                <label className="tab" for="radio-25">No</label>
                            </div>
                        </div>
                        {radio === 'yes' && (
                            <div className="">
                                <label for="">Optional : Add Assets and Liabilities (Select all applicable).
                                    Note that the Assets and Liabilities will not be a part of your Will.
                                    Instead it will be included as an annexure. This will help your executor to
                                    know your assets and liabilities.</label>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="realEstate"
                                            id="realEstate" checked={isCheck.realEstate} onChange={handleToggle} />
                                        <label className="form-check-label" for="realEstate">
                                            Real Estate
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="bank" id="bank" checked={isCheck.bank} onChange={handleToggle} />
                                        <label className="form-check-label" for="bank">
                                            Bank
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="share" id="share" checked={isCheck.share} onChange={handleToggle} />
                                        <label className="form-check-label" for="share">
                                            Share
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="bonds" id="bonds" checked={isCheck.bonds} onChange={handleToggle} />
                                        <label className="form-check-label" for="bonds">
                                            Bonds
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="superAnnuation"
                                            id="superAnnuation" checked={isCheck.superAnnuation} onChange={handleToggle} />
                                        <label className="form-check-label" for="superAnnuation">
                                            Superannuation
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="family"
                                            id="family" checked={isCheck.family} onChange={handleToggle} />
                                        <label className="form-check-label" for="family">
                                            Family companies/trusts
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="personal"
                                            id="personal" checked={isCheck.personal} onChange={handleToggle} />
                                        <label className="form-check-label" for="personal">
                                            Personal items/specific bequests
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="details"
                                            id="details" checked={isCheck.details} onChange={handleToggle} />
                                        <label className="form-check-label" for="details">
                                            Details of Liabilities
                                        </label>
                                    </div>
                                </div>
                                {isCheck.realEstate && (
                                    <>
                                        <div class="input-wrapper">
                                            <label for="" class="form-label">Select the number of Real estate you hold</label>
                                            <select name="initialSelect" class="form-select" defaultValue={selectedValue} id=""  onChange={(e) => setSelectedValue(Number(e.target.value))}>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                            </select>
                                        </div>
                                        {fields.map((field, index) => (
                                            <div key={index}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="" className="form-label">Ownership Type {index + 1 === 1 ? '' : ` for ${index + 1} property`}</label>
                                                    <select name="selectValue" value={field.selectValue} onChange={(e) => handleFieldChange(index, e)} className="form-select">
                                                        <option value="">Select Ownership Type</option>
                                                        {options.map((option, idx) => (
                                                            <option key={idx} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="input-wrapper">
                                                    <label htmlFor="" className="form-label">Real Estate Address  {index + 1 === 1 ? '' : ` for ${index + 1}nd property`}</label>
                                                    <textarea name="textareaValue" value={field.textareaValue} onChange={(e) => handleFieldChange(index, e)} className="form-control" />
                                                    <small>You can enter more than one real estate address.</small>
                                                </div>
                                            </div>
                                        ))}

                                        {/* <div class="input-wrapper">
                                            <label for="" class="form-label">Real Estate: Enter address of 4th property</label>
                                            <textarea name="" id="" class="form-control"></textarea>
                                            <small>You can enter more then 1 real estate information.</small>
                                        </div> */}
                                    </>
                                )}

                                {isCheck.bank && (
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Bank Details</label>
                                        <textarea name="bankDetails" id="" className="form-control" value={formvalue.bankDetails} onChange={handleInputChange} />
                                        {errors.bankDetails && <p className="text-danger">{errors.bankDetails}</p>}
                                    </div>
                                )}
                                {isCheck.share && (
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Shares</label>
                                        <textarea name="shareDetails" id="" className="form-control" value={formvalue.shareDetails} onChange={handleInputChange} />
                                        <small>Provide details of shares you hold, if any.</small>
                                        {errors.shareDetails && <p className="text-danger">{errors.shareDetails}</p>}
                                    </div>
                                )}
                                {isCheck.bonds && (
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Bonds</label>
                                        <textarea name="bondsDetails" id="" className="form-control" value={formvalue.bondsDetails} onChange={handleInputChange} />
                                        {errors.bondsDetails && <p className="text-danger">{errors.bondsDetails}</p>}
                                    </div>
                                )}

                                {isCheck.superAnnuation && (
                                    <>
                                        <div class="input-wrapper">
                                            <label for="" class="form-label">Superannuation. For your superannuation and life insurance policy, you will need to make a binding death nomination with your fund or policy holder to properly dispose of these assets.</label>
                                            <textarea name="policy" id="" class="form-control" value={formvalue.policy} onChange={handleInputChange} />
                                            {errors.policy && <p className="text-danger">{errors.policy}</p>}
                                        </div>
                                        <div class="input-wrapper">
                                            <label for="" class="form-label">Liabilities Details (Optional)</label>
                                            <textarea name="liabilitiesDetails" id="" class="form-control" value={formvalue.liabilitiesDetails} onChange={handleInputChange} />
                                            <small>Mortgage, Personal loan, Credit card debt etc</small>
                                            {errors.liabilitiesDetails && <p className="text-danger">{errors.liabilitiesDetails}</p>}
                                        </div>
                                        <div class="input-wrapper">
                                            <label for="">Death Benefit Nomination:</label>
                                            <div>
                                                <input type="radio" id="radio-22" name="benefit" value='yes' checked={benefitradio === 'yes'} onChange={handlebenefitChange} />
                                                <label class="tab" for="radio-22">Yes</label>
                                                <input type="radio" id="radio-23" name="benefit" value='no' checked={benefitradio === 'no'} onChange={handlebenefitChange} />
                                                <label class="tab" for="radio-23">No</label>
                                                
                                            </div>
                                        </div>
                                    </>
                                )}
                                {isCheck.family && (
                                    <>
                                        <div className="input-wrapper">
                                            <label for="" className="form-label">Family companies/trusts details</label>
                                            <textarea name="familyCompanyDetails" id="" className="form-control" value={formvalue.familyCompanyDetails} onChange={handleInputChange} />
                                            {errors.familyCompanyDetails && <p className="text-danger">{errors.familyCompanyDetails}</p>}
                                        </div>
                                        <div className="input-wrapper">
                                            <label for="" className="form-label">Personal items/specific bequests</label>
                                            <textarea name="personalDetails" id="" className="form-control" value={formvalue.personalDetails} onChange={handleInputChange} />
                                            {errors.familyCompanyDetails && <p className="text-danger">{errors.familyCompanyDetails}</p>}
                                        </div>
                                    </>
                                )}

                            </div>
                        )}

                    </div>
                    <div className="col-12">
                        <div className="input-wrapper">
                            <label for="" className="form-label">Funeral details (Optional)</label>
                            <textarea name="funeralDetails" id="" className="form-control" value={formvalue.funeralDetails} onChange={handleInputChange} />
                            <small>Details like how you wish to perform your last rituals etc ( cremation,
                                burial and other details)</small>
                        </div>
                    </div>
                </div>
                <div>
                    <button type='submit' className="button-style3">Next</button>
                    <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/pets')}>Back</span>
                </div>
            </form>
        </>
    )
}

export default AssetsLiabilities