import React, { useEffect, useState } from 'react'
import axios from 'axios'
import API_ENDPOINTS from '../config/apiConfig';
import MyComponent from '../components/MyComponent';
import ReviewComponent from '../components/ReviewComponent';

function Home() {
    const [banners, setBanners] = useState([]);
    const [sections, setSections] = useState([]);
    const [services, setServices] = useState([]);
    const [resviews, setReviews] = useState([]);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBanner = async () => {
            console.log()
            try {
                const response = await axios.get(API_ENDPOINTS.BANNERHOMEPAGE);

                setBanners(response.data)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        const fetchSection = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PAGESSECTIONS('1'));
                const parsedData = response.data.map(section => ({
                    ...section,
                    details: JSON.parse(section.other_details)
                }));
                setSections(parsedData)
                console.log(parsedData)
            } catch (error) {
                console.log(error)
            }
        }

        const fetchServices = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.SERVICES);

                setServices(response.data)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        const fetchReview = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.REVIEWS);

                setReviews(response.data)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        const fetchBlogs = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.BLOGS);

                setBlogs(response.data)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        fetchBanner();
        fetchSection();
        fetchServices();
        fetchReview();
        fetchBlogs();
    }, []);
    return (
        <>

            <main>
                <section className="hero-section">
                    <div className="hero-slider swiper-container">
                        <div className="swiper-wrapper">
                            {banners.map((banner, index) => (
                                <div key={index} className="swiper-slide" style={{ backgroundImage: `url("${API_ENDPOINTS.BASE_URL + banner.image}")` }}>
                                    <div className="content">
                                        <h5>Will By Lawyers</h5>
                                        <h2>{banner.title}</h2>
                                        <p>{banner.description}</p>
                                        <a href="#url" className="button-style1">Make an Appointment</a>
                                    </div>
                                    <div className="image-wrapper">
                                        <img src="assets/images/slider-man1.webp" loading="lazy" alt="slider-man1" />
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                </section>

                <section className="about-section section-padding">
                    <div className="container">
                        <div className="row flex-row-reverse">

                            {sections.map((section, index) => (

                                <>

                                    {section.name === 'achievement' && (
                                        <div className="col-lg-6">
                                            <div className="stats-area">
                                                <div className="item">
                                                    <div className="icon">
                                                        <img src="assets/images/success-icon.webp" loading="lazy" alt="success icon" />
                                                    </div>
                                                    <div className="info">
                                                        <h4>{section.details.headline_1}</h4>
                                                        <p>{section.details.description_1}</p>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="icon">
                                                        <img src="assets/images/recommended.webp" loading="lazy" alt="recommended icon" />
                                                    </div>
                                                    <div className="info">
                                                        <h4>{section.details.headline_2}</h4>
                                                        <p>{section.details.description_2}</p>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="icon">
                                                        <img src="assets/images/expert.webp" loading="lazy" alt="expert icon" />
                                                    </div>
                                                    <div className="info">
                                                        <h4>{section.details.headline_3}</h4>
                                                        <p>{section.details.description_3}</p>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="icon">
                                                        <img src="assets/images/quality.webp" loading="lazy" alt="quality icon" />
                                                    </div>
                                                    <div className="info">
                                                        <h4>{section.details.headline_4}</h4>
                                                        <p>{section.details.description_4}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {section.name === 'about' && (
                                        <div className="col-lg-6">
                                            <div className="content-area">
                                                <div className="section-heading">
                                                    <h5>Who We Are</h5>
                                                    <h2>{section.headline}</h2>
                                                    <MyComponent content={section.description} />
                                                    <ul className="checklist">
                                                        {section.details.map(service => (
                                                            <li>{service.value}</li>
                                                        ))}
                                                        {/* <li>Expert Legal Serives</li>
                                        <li>Expert Attorneys</li>
                                        <li>No need extra cost for consultation</li>
                                        <li>Highly Recommended</li>
                                        <li>100% Success Rate</li> */}
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    )}

                                    <></>


                                </>
                            ))}


                        </div>
                    </div>
                </section>
                {sections.map((section, index) => (
                    <>
                        {section.name == 'expert' && (
                            <section className="expert-section section-padding" style={{ backgroundImage: 'url("assets/images/expert-bg.webp")' }}>
                                <div className="container">
                                    <div className="row position-relative z-3">
                                        <div className="col-lg-6">
                                            <div className="content-area">
                                                <div className="section-heading">
                                                    <h5 className="text-white">we are expert</h5>
                                                    <h2 className="text-white">{section.headline}</h2>
                                                    <p className="text-white"> <MyComponent content={section.description} /></p>
                                                    <div className="accordian-wrapper">
                                                        <div className="item">
                                                            <div className="accordian-title">
                                                                <p>Legal/Knowledge Skills</p>
                                                                <p>95%</p>
                                                            </div>
                                                            <div className="progress" role="progressbar" aria-label="Animated Success example"
                                                                aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar bg-theme progress-bar-animated" style={{ width: "95%" }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="accordian-title">
                                                                <p>Prior Knowledge</p>
                                                                <p>80%</p>
                                                            </div>
                                                            <div className="progress" role="progressbar" aria-label="Success example"
                                                                aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar bg-theme" style={{ width: "80%" }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="accordian-title">
                                                                <p>Preparation</p>
                                                                <p>70%</p>
                                                            </div>
                                                            <div className="progress" role="progressbar" aria-label="Success example"
                                                                aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar bg-theme" style={{ width: "70%" }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="accordian-title">
                                                                <p>Client service</p>
                                                                <p>70%</p>
                                                            </div>
                                                            <div className="progress" role="progressbar" aria-label="Success example"
                                                                aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar bg-theme" style={{ width: "70%" }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="accordian-title">
                                                                <p>Professionality</p>
                                                                <p>75%</p>
                                                            </div>
                                                            <div className="progress" role="progressbar" aria-label="Success example"
                                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar bg-theme" style={{ width: "75%" }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="image-area">
                                                <img src={API_ENDPOINTS.BASE_URL + section.image} loading="lazy" alt="expet section image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}


                        {section.name === 'services' && (
                            <section className="service-section section-padding">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="section-heading text-center">
                                                <h5>Services</h5>
                                                <h2>{section.headline}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-slider-wrapper">
                                        <div className="service-slider swiper-container">
                                            <div className="swiper-wrapper">
                                                {services.map((service, indexService) => (
                                                    <div className="swiper-slide">
                                                        <div className="image-area">
                                                            <img src={API_ENDPOINTS.BASE_URL + service.home_image} loading="lazy" alt="expert service" />
                                                        </div>
                                                        <div className="info">
                                                            <h4><a href="#url">{service.name}</a></h4>
                                                            <MyComponent content={service.short_description} />
                                                            <a href="#url" className="button-style3">Read More <i
                                                                className="fa-solid fa-arrow-right"></i></a>
                                                        </div>
                                                    </div>

                                                ))}
                                                {/* <div className="swiper-slide">
                                    <div className="image-area">
                                        <img src="assets/images/expert-service.webp" loading="lazy" alt="expert service"/>
                                    </div>
                                    <div className="info">
                                        <h4><a href="#url">Expert Lawyers</a></h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adpising elit, sed do e aliqua. Quis e
                                            viverra maecenas</p>
                                        <a href="#url" className="button-style3">Read More <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="image-area">
                                        <img src="assets/images/cutomized-planning.webp" loading="lazy"
                                            alt="cutomized planning"/>
                                    </div>
                                    <div className="info">
                                        <h4><a href="#url">Customized Planning</a></h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adpising elit, sed do e aliqua. Quis e
                                            viverra maecenas</p>
                                        <a href="#url" className="button-style3">Read More <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="image-area">
                                        <img src="assets/images/free-process.webp" loading="lazy" alt="free process"/>
                                    </div>
                                    <div className="info">
                                        <h4><a href="#url">Hassle-Free Process</a></h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adpising elit, sed do e aliqua. Quis e
                                            viverra maecenas</p>
                                        <a href="#url" className="button-style3">Read More <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="image-area">
                                        <img src="assets/images/transparent-pricing.webp" loading="lazy"
                                            alt="transparent pricing"/>
                                    </div>
                                    <div className="info">
                                        <h4><a href="#url">Transparent Pricing</a></h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adpising elit, sed do e aliqua. Quis e
                                            viverra maecenas</p>
                                        <a href="#url" className="button-style3">Read More <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="image-area">
                                        <img src="assets/images/expert-service.webp" loading="lazy" alt="expert service" />
                                    </div>
                                    <div className="info">
                                        <h4><a href="#url">Expert Lawyers</a></h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adpising elit, sed do e aliqua. Quis e
                                            viverra maecenas</p>
                                        <a href="#url" className="button-style3">Read More <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                    </>
                ))}
                <section className="cta-section1 section-padding" style={{ backgroundImage: 'url("assets/images/silder1.webp")' }}>
                    <div className="container">
                        <div className="cta-wrapper">
                            <img src="assets/images/court-icon.webp" loading="lazy" alt="court icon" />
                            <h3>We fight for your right. Without win, we don't take any money.</h3>
                            <a href="#url" className="button-style4">Get started <i
                                className="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </section>
                {sections.map((section, index) => (
                    <>
                        {section.name === 'testimonial' && (
                            <section className="testimonial-section">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 col-md-10">
                                            <div className="testimonial-slider-wrapper position-relative">
                                                <div className="section-heading text-center">
                                                    <h5>testimonial</h5>
                                                    <h2>{section.headline}</h2>
                                                </div>
                                                <ReviewComponent />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}

                    </>
                ))}

                <section className="free-consultation section-padding"
                    style={{ backgroundImage: 'url("assets/images/free-consultation-bg.webp")' }}>
                    <div className="container">
                        <div className="wrapper">
                            <div className="image-area position-relative">
                                <img src="assets/images/consultation-person-image.webp" loading="lazy"
                                    alt="consultation person image" />
                            </div>
                            <div className="form-wrapper">
                                <div className="section-heading">
                                    <h5 className="text-white">Schedule Your</h5>
                                    <h2 className="text-white">Free Consultation</h2>
                                </div>
                                <form action="">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="Frst Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="Last Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="Phone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-wrapper">
                                                <textarea name="" id="" className="form-control" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-wrapper">
                                                <button type="submit" className="button-style1">Submit <i
                                                    className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-section section-padding">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="section-heading text-center">
                                    <h5>Blog</h5>
                                    <h2>latest blog psot</h2>
                                </div>
                            </div>
                        </div>
                        <div className="blogs-wrapper">
                            <div className="row">
                            {blogs.map((blog, index) => (
                                <>
                                <div className="col-lg-4 col-md-6">
                                    <div className="inner">
                                        <div className="image-wrapper">
                                            <img src={API_ENDPOINTS.BASE_URL+blog.image} loading="lazy" alt="blog image" />
                                            <div className="button-style1">June 10, 2024</div>
                                        </div>
                                        <div className="content-area">
                                            <h4><a href="#url">{blog.title}</a></h4>
                                            <MyComponent content={blog.short_description} />
                                            <a href="#url" className="button-link">Continue Reading <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                </>
                            ))}
                                
                                {/* <div className="col-lg-4 col-md-6">
                                    <div className="inner">
                                        <div className="image-wrapper">
                                            <img src="assets/images/blog-image2.webp" loading="lazy" alt="blog image" />
                                            <div className="button-style1">June 10, 2024</div>
                                        </div>
                                        <div className="content-area">
                                            <h4><a href="#url">Know About Will Law</a></h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                                gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                            <a href="#url" className="button-link">Continue Reading <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="inner">
                                        <div className="image-wrapper">
                                            <img src="assets/images/blog-image3.webp" loading="lazy" alt="blog image" />
                                            <div className="button-style1">June 10, 2024</div>
                                        </div>
                                        <div className="content-area">
                                            <h4><a href="#url">Business Needs Customers Law</a></h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                                gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                            <a href="#url" className="button-link">Continue Reading <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default Home