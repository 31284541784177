import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';

function MyPlansComponent() {
    const [planData, setPlanData] = useState([]);
    useEffect(() => {

        const fetchPlans = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PLANS)
                const data = response.data;
                const groupedPlans = data.reduce((acc, plan) => {
                    const planIndex = acc.findIndex(p => p.main_id === plan.main_id);
                    if (planIndex !== -1) {
                        acc[planIndex].services.push({
                            id: plan.id,
                            serviceName: plan.name,
                            serviceDescription: plan.provide,
                            // Add other service-related fields if needed
                        });
                    } else {
                        acc.push({
                            main_id: plan.main_id,
                            name: plan.plans_names,
                            validity: plan.validity,
                            amount: plan.amount,
                            services: [{
                                id: plan.id,
                                serviceName: plan.name,
                                serviceDescription: plan.provide,
                                // Add other service-related fields if needed
                            }]
                        });
                    }
                    return acc;
                }, []);

                setPlanData(groupedPlans);
                console.log(groupedPlans)
            } catch (error) {
                console.error('Error fetching the data:', error);
            }
        }

        fetchPlans();
    }, [])
    return (
        <>
            {planData.map(plan => (
    <div className="col-lg-4 col-md-6" key={plan.planId}>
        <div className={`inner ${plan.isActive ? 'active' : ''}`}>
            <div className="head">
                <div className="title">
                    <h4>${plan.amount}</h4>
                    <p>
                        {plan.validity === 'month' 
                            ? 'per Month' 
                            : plan.validity === 'year' 
                            ? 'per Year' 
                            : plan.validity}
                    </p>
                </div>
                <h3>{plan.name}</h3>
            </div>
            <div className="body-main">
                <ul>
                    {plan.services.map(service => (
                        <li key={service.id}>
                        {service.serviceDescription === 'on' ? 
                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#3b9e62"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 25 20"
                                            style={{ enableBackground: 'new 0 0 25 20' }}
                                            xmlSpace="preserve"
                                        >
                                            <path
                                            d="M7.9,13.1C11.5,6.3,16.8,1.6,24.4,0c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1C16.8,3.5,12.1,11.7,9.2,19.1 
                                                c0,0,0,0-0.1,0.1c-1,0.3-1.9,0.6-2.9,0.9c0,0,0,0-0.1,0c0,0,0,0,0-0.1c-0.8-3.6-2.3-7.6-5.7-9.6c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
                                                C3.8,7.9,6.2,10.2,7.9,13.1z"
                                            />
                                        </svg>
                        : 
                        <svg
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M16.9498 8.46447C17.3404 8.07394 17.3404 7.44078 16.9498 7.05025C16.5593 6.65973 15.9261 6.65973 15.5356 7.05025L12.0001 10.5858L8.46455 7.05025C8.07402 6.65973 7.44086 6.65973 7.05033 7.05025C6.65981 7.44078 6.65981 8.07394 7.05033 8.46447L10.5859 12L7.05033 15.5355C6.65981 15.9261 6.65981 16.5592 7.05033 16.9497C7.44086 17.3403 8.07402 17.3403 8.46455 16.9497L12.0001 13.4142L15.5356 16.9497C15.9261 17.3403 16.5593 17.3403 16.9498 16.9497C17.3404 16.5592 17.3404 15.9261 16.9498 15.5355L13.4143 12L16.9498 8.46447Z"
                                                    fill="#ef6c00"
                                                  />
                                                </g>
                                              </svg>

                        }
                        {service.serviceName}</li>
                            
                       
                    ))}
                </ul>
            </div>
            <div className="foot">
                <a href="#url" className="button-style1">
                    Choose Plan <i className="fa-solid fa-arrow-right"></i>
                </a>
                <p className="mt-2">
                    <small>
                        <a href="#url">* Terms and conditions apply</a>
                    </small>
                </p>
            </div>
        </div>
    </div>
))}

           
        </>
    )
}

export default MyPlansComponent