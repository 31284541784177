import React from 'react'

function Liabilities() {
  return (
    <>
        <form action="" id="assets-liabilities">
                        <h2>Add Assets and Liabilities</h2>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-wrapper">
                                    <label for="">Do you wish to provide your assets and liabilities details? Note
                                        that the Assets and Liabilities will not be a part of your Will. Instead, it
                                        will be included as an annexure. This will help your executor to know your
                                        assets and liabilities.</label>
                                    <div>
                                        <input type="radio" id="radio-24" name="assets-liabilities-details"
                                            value="yes" />
                                        <label className="tab" for="radio-24">Yes</label>
                                        <input type="radio" id="radio-25" name="assets-liabilities-details"
                                            value="no" />
                                        <label className="tab" for="radio-25">No</label>
                                    </div>
                                </div>

                                <div className="hidden-option-12">
                                    <label for="">Optional : Add Assets and Liabilities (Select all applicable).
                                        Note that the Assets and Liabilities will not be a part of your Will.
                                        Instead it will be included as an annexure. This will help your executor to
                                        know your assets and liabilities.</label>
                                    <div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="real-state" />
                                            <label className="form-check-label" for="real-state">
                                                Real Estate
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="Bank" />
                                            <label className="form-check-label" for="Bank">
                                                Bank
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="share" />
                                            <label className="form-check-label" for="share">
                                                Share
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="bonds" />
                                            <label className="form-check-label" for="bonds">
                                                Bonds
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="Superannuation" />
                                            <label className="form-check-label" for="Superannuation">
                                                Superannuation
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="companies-trusts" />
                                            <label className="form-check-label" for="companies-trusts">
                                                Family companies/trusts
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="items-specific" />
                                            <label className="form-check-label" for="items-specific">
                                                Personal items/specific bequests
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="Details-Liabilities" />
                                            <label className="form-check-label" for="Details-Liabilities">
                                                Details of Liabilities
                                            </label>
                                        </div>
                                    </div>
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Bank Details</label>
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </div>
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Shares</label>
                                        <textarea name="" id="" className="form-control"></textarea>
                                        <small>Provide details of shares you hold, if any.</small>
                                    </div>
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Bonds</label>
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </div>
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Family companies/trusts details</label>
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </div>
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Personal items/specific bequests</label>
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-wrapper">
                                    <label for="" className="form-label">Funeral details (Optional)</label>
                                    <textarea name="" id="" className="form-control"></textarea>
                                    <small>Details like how you wish to perform your last rituals etc ( cremation,
                                        burial and other details)</small>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a href="javascript:void(0)" className="button-style3">Next</a>
                            <a href="javascript:void(0)" className="button-style3 fade-className">Back</a>
                        </div>
                    </form>
    </>
  )
}

export default Liabilities