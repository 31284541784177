// src/config/apiConfig.js
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
const API_BASE_URL = 'https://localseocompany.in/node';
const API_ENDPOINTS = {
    BASE_URL: `${API_BASE_URL}/uploads/`,
    BANNERHOMEPAGE: `${API_BASE_URL}/api/bannerData`,
    PAGESSECTIONS: (id) => `${API_BASE_URL}/api/sectionData/${id}`,
    SERVICES: `${API_BASE_URL}/api/servicesData`,
    REVIEWS: `${API_BASE_URL}/api/reviewsData`,
    BLOGS: `${API_BASE_URL}/api/blogsData`,
    PLANS: `${API_BASE_URL}/api/plansData`,
    QUESTIONS: `${API_BASE_URL}/api/faqsData`,
    GETWEBSITEDETAIL: (id) => `${API_BASE_URL}/api/website/${id}`,
    INQUIRY: `${API_BASE_URL}/api/mail/`,

    // after login apis
    REGISTER: `${API_BASE_URL}/customer/register`,
    LOGIN: `${API_BASE_URL}/customer/login`,

    FAMILYADD: `${API_BASE_URL}/customer/familyAdd`,
    FAMILYMEMBERS: (id) => `${API_BASE_URL}/customer/family/${id}`,
    FAMILYMEMBERDELETE: (id) => `${API_BASE_URL}/customer/deleteFamilyMember/${id}`,
    FAMILYMEMBERDETAILS: (id) => `${API_BASE_URL}/customer/detailsFamilyMember/${id}`,
    FAMILYUPDATE: `${API_BASE_URL}/customer/familyUpdate`,

    PARSONALACTION: `${API_BASE_URL}/customer/customerActions`,
    PARSONALDETAILS: (id) => `${API_BASE_URL}/customer/customerDetails/${id}`,
    SUBSTITUTEACTION: `${API_BASE_URL}/customer/substituteAction`,
    SUBSTITUTEDATA: (id) => `${API_BASE_URL}/customer/substituteExecutor/${id}`,
    SUBSTITUTEDELETE: (id) => `${API_BASE_URL}/customer/deleteSubstituteExecutor/${id}`,
    SUBSTITUTEDETAILS: (id) => `${API_BASE_URL}/customer/detailsSubstituteExecutor/${id}`,
    EXECUTORACTION: `${API_BASE_URL}/customer/executorsAction`,
    EXECUTORDETAILS: (id) => `${API_BASE_URL}/customer/executorsDetails/${id}`,

    ESTATEACTION: `${API_BASE_URL}/customer/estateAction`,
    ESTATEDETAILS: (id) => `${API_BASE_URL}/customer/estateDetails/${id}`,

    RESIDUALESTATEACTION: `${API_BASE_URL}/customer/residualEstateAction`,
    RESIDUALESTATEDETAILS: (id) => `${API_BASE_URL}/customer/residualEstateDetails/${id}`,


    PETLISTS: (id) => `${API_BASE_URL}/customer/petsList/${id}`,
    PETSACTION: `${API_BASE_URL}/customer/petsAction`,
    PETSDELETE: (id) => `${API_BASE_URL}/customer/deletePets/${id}`,
    PETSDETAILS: (id) => `${API_BASE_URL}/customer/detailsPets/${id}`,

    COUNTRIES: `${API_BASE_URL}/api/countries`,
    STATES: (id) => `${API_BASE_URL}/api/states/${id}`,


    PLANSLIST: `${API_BASE_URL}/api/plans`,
    PLANDETAIL: (id) => `${API_BASE_URL}/plan/${id}`,

    LIABILITIESACTION: `${API_BASE_URL}/customer/liabilitiesAction`,
    LIABILITIESDETAILS: (id) => `${API_BASE_URL}/customer/liabilitiesDetails/${id}`,

};

export default API_ENDPOINTS;