import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import Dashboard from './page/dashboard';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import { useNavigate, useLocation } from 'react-router-dom'
const Comman = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() =>{
        import('./dashboard.css');
        const token = localStorage.getItem('authenticatetoken');
        if (!token) {
            navigate('/login');
        }
    },[navigate]);

    const handleLogout = () =>{
        const token = localStorage.getItem('authenticatetoken');
        if(token){
            localStorage.removeItem('authenticatetoken');
            localStorage.removeItem('authenticateid');
            navigate('/login');
        }else{
            navigate('/login');
        }
    }
    return (
        <>
            <main>
                <div className="top text-center">
                    <a onClick={() => navigate('/about-your-self')}><img src="assets/images/logo.webp" alt="" /></a>
                </div>
                <section className="dashboard-section">
                    <div className="container">
                        <div className="dashboard-wrapper">
                            <Sidebar />
                            <div className='content-area'> 
                                <div className='d-flex justify-content-end'>
                                    <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
                                </div>
                                <div className='form-wrapper'>
                                { children }
                                </div>
                            </div>
                                
                           
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Comman;
