import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import axios from 'axios';
import API_ENDPOINTS from '../../../config/apiConfig';

function Residual() {
    const navigate = useNavigate();
    const [customerid, setCustomerId] = useState('');
    const [redio, setRedio] = useState('no');
    const [radio, setRadio] = useState('no');
    const [formValue, setFormValue] = useState({
        age: '',
        details: ''
    })

    const [errors, setErrors] = useState({
        age: '',
        details: ''
    })

    useEffect(() => {
        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.RESIDUALESTATEDETAILS(localStorage.getItem('authenticateid')));
                if(response.status === 200){
                    const data = response.data;
                    if(data !== ''){
                        setCustomerId(data.id)
                        setRedio(data.age_option)
                        setRadio(data.charit_option)
                        setFormValue({
                            age: data.age,
                            details: data.details_of_gift,
                            
                        })
                    }
                    
                    
                }
            } catch (error) {
                console.log(error)
            }
        }
        
        fetchData();
    },[])

    const headlebeneficiary = (event) =>{
        setRedio(event.target.value);
    }

    const headlegift = (event) => {
        setRadio(event.target.value)
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
        let hasError = false;
        const newErrors = {
            age: '',
            details: '',
        }
        if(redio === 'yes'){
            if(!formValue.age){
                newErrors.age = 'This field is required';
                hasError = true;
            }
        }
        if(radio === 'yes'){
            if(!formValue.details){
                newErrors.details = 'This field is required';
                hasError = true;
            }
        }
        if(hasError){
            setErrors(newErrors);
        }else{
            if((redio !== 'undefined' && redio !== '') && (radio !== 'undefined' && radio !== '')){
                const formData = new FormData();
                if(customerid){
                    formData.append('id' , customerid);
                }
                formData.append('customer_id' , localStorage.getItem('authenticateid'));
                formData.append('age_option' , redio);
                formData.append('age' , formValue.age);
                formData.append('charit_option' , radio);
                formData.append('details_of_gift' , formValue.details);
                try {
                    const response = await axios.post(API_ENDPOINTS.RESIDUALESTATEACTION, formData, {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      });
                      if (response.status === 200) {
                        setCustomerId('')
                        alert({
                          text: response.data,
                          type: 'success',
                        });
                        navigate('/pets')
                      } else {
                        alert({
                          text: response.data,
                          type: 'error',
                        });
                      }
                } catch (error) {
                    alert({
                        text: error,
                        type: 'error',
                      });
                }


            }else{
                alert({
                    text: 'Please Select Any Option',
                    type: 'error',
                  });
            }
        }

    }
  return (
    <>
        <form action="" id="residual-estate" onSubmit={handleSubmit}>
                        <h2>Share of Residual Estate</h2>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-wrapper">
                                    <label for="">From your listed beneficiaries above, are any person/s under the
                                        age of 18? *</label>
                                    <div>
                                
                                        <input type="radio" id="radio-18" name="beneficiary-age" value="yes" checked={redio === 'yes'} onChange={headlebeneficiary} />
                                        <label className="tab" for="radio-18">Yes</label>
                                        <input type="radio" id="radio-19" name="beneficiary-age" value="no" checked={redio === 'no'} onChange={headlebeneficiary} />
                                        <label className="tab" for="radio-19">No</label>
                                    </div>
                                    {redio === 'yes' && (
                                        <div className="">
                                        <div className="input-wrapper">
                                            <select name="age" id="" defaultValue={formValue.age} onChange={(e) => setFormValue((prev) => ({...prev,age:e.target.value}))}  className="form-select">
                                                <option value={'18'}>18</option>
                                                <option value={'21'}>21</option>
                                                <option value={'25'}>25</option>
                                            </select>
                                            {errors.age && <p className="text-danger">{errors.age}</p>}
                                        </div>
                                    </div>
                                    )}
                                    
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-wrapper">
                                    <label for="">Do you wish to leave a gift for someone or a Charity? (Money or
                                        Possession)</label>
                                    <div>
                                        <input type="radio" id="radio-20" name="leave-gift" value="yes" checked={radio === 'yes'} onChange={headlegift} />
                                        <label className="tab" for="radio-20">Yes</label>
                                        <input type="radio" id="radio-21" name="leave-gift" value="no" checked={radio === 'no'} onChange={headlegift} />
                                        <label className="tab" for="radio-21">No</label>
                                    </div>
                                    {radio === 'yes' && (
                                        <div className="option-gift">
                                        <div className="input-wrapper">
                                            <label for="">Details of gift ( Name of Person and gift. More than 1
                                                person can be added)</label>
                                            <textarea name="details" className="form-control"
                                                placeholder="My car to Mr.X , $ 5000 to XYZ orphanage / old age home"
                                                id="" value={formValue.details} onChange={(e) => setFormValue((prev) => ({...prev,details:e.target.value}))}/>
                                                {errors.details && <p className="text-danger">{errors.details}</p>}
                                        </div>
                                    </div>
                                    )}
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type='submit' className="button-style3">Next</button>
                            <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/estate')}>Back</span>
                        </div>
                    </form>
    </>
  )
}

export default Residual