import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';
import MyComponent from './MyComponent';

function MyFaqsComponent() {
    const [questions, setQuestion] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.QUESTIONS);
                setQuestion(response.data)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])
    return (
        <>
            {questions.map((question, index) => (
    <div className="accordion-item" key={index}>
        <h2 className="accordion-header">
            <button 
                className="accordion-button collapsed" 
                type="button" 
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapsmainone_${index}`} 
                aria-expanded="false"
                aria-controls={`flush-collapsmainone_${index}`}>
                <span>{index + 1}</span> {question.question}
            </button>
        </h2>
        <div 
            id={`flush-collapsmainone_${index}`} 
            className="accordion-collapse collapse"
            data-bs-parent="#mainfaq">
            <div className="accordion-body">
                <MyComponent content={question.answers} />
            </div>
        </div>
    </div>
))}

        </>
    )
}

export default MyFaqsComponent