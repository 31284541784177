import React, { useEffect, useState } from 'react'
import MyPlansComponent from '../components/MyPlansComponent'
import axios from 'axios';
import API_ENDPOINTS from '../config/apiConfig';

function Pricing() {
    const [sections, setSection] = useState([]);
    useEffect(() => {
        const fetchSection = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PAGESSECTIONS('3'));
                const parsedData = response.data.map(section => ({
                    ...section,
                    details: section.other_details ? JSON.parse(section.other_details) : {}
                }));
                setSection(parsedData);
                console.log(parsedData);
            } catch (error) {
                console.log(error)
            }
        }
        fetchSection();
    },[])
  return (
    <>
      <main>
        <section className="breadcrum-section" style={{backgroundImage: 'url("assets/images/faq-bg.webp")'}}>
            <div className="container">
                <h1 className="text-white">Pricing</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html" className="text-white">Home</a></li>
                        <li className="breadcrumb-item active text-white" aria-current="page">Pricing</li>
                    </ol>
                </nav>
            </div>
        </section>
        {sections.map((section, index) => (
            <>
                {section.name === 'services' && (
                    <section className="pricing-section section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-8">
                                    <div className="section-heading text-center">
                                        <h5>Legal Service Charges</h5>
                                        <h2>Affordable Pricing Plan</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-wrapper">
                                <div className="row">

                                    <MyPlansComponent />
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {section.name === 'schedule' && (
                    <section className="schedule-consultation section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="section-heading text-center">
                            <h2>{section.headline}</h2>
                        </div>
                    </div>
                </div>
                <div className="schedule-wrapper">
                    <div className="item">
                        <i className="fa-solid fa-calendar-days"></i>
                        <div className="info">
                            <h3>Schedule A Time</h3>
                            <p>{section.details.description_1}</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-calendar-days"></i>
                        <div className="info">
                            <h3>Meet A Consultant</h3>
                            <p>{section.details.description_2}</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-calendar-days"></i>
                        <div className="info">
                            <h3>Schedule A Time</h3>
                            <p>{section.details.description_3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                )} 
            </>
        ))}
        
        
      </main>
    </>
  )
}

export default Pricing