import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import axios from 'axios';
import API_ENDPOINTS from '../../../config/apiConfig';
import { useEffect } from 'react';
function Estate() {
    const navigate = useNavigate();
    const [customerid, setCustomerId] = useState('');
    const [readio, setReadio] = useState('leave')
    const headle = (event) =>{
        setReadio(event.target.value);
    }
    const [formValue, setFormValue] = useState({
        fullName: '',
        address: '',
        relation: '',
        number: '',
        fullName1: '',
        address1: '',
        relation1: '',
        parcent: ''

    })
    const [errors, setErrors] = useState({
        fullname: '',
        address: '',
        relation: '',
        number: '',
        fullname1: '',
        address1: '',
        relation1: '',
        parent: ''

    })

    useEffect(() =>{

        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.ESTATEDETAILS(localStorage.getItem('authenticateid')));
                if(response.status === 200){
                    const data = response.data;
                    if(data !== ''){
                        setCustomerId(data.id)
                        setReadio(data.select_beneficiary)
                        setFormValue({
                            fullName: data.full_name,
                            address: data.address,
                            relation: data.relationship,
                            number: data.beneficiary_number,
                            fullName1: data.beneficiary_full_name,
                            address1: data.beneficiary_address,
                            relation1: data.beneficiary_relation,
                            parcent: data.share_percent
                        })
                    }
                    
                    
                }
                console.log(readio)
            } catch (error) {
                console.log(error)
            }
        }
        
        fetchData();
    },[])

    const handleMainInputChange = (event) =>{
        const { name, value } = event.target;
        setFormValue((prevFamily) => ({
            ...prevFamily,
            [name]: value,
        }));
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
        let hasError = false;
        const newErrors = {
            fullname: '',
            address: '',
            relation: '',
            number: '',
            fullname1: '',
            address1: '',
            relation1: '',
            parent: ''
        }
        if(readio === 'one_person'){
            if(!formValue.fullName){
                newErrors.fullname = 'This field is required';
                hasError = true;
            }
            if(!formValue.address){
                newErrors.address = 'This field is required';
                hasError = true;
            }
            if(!formValue.relation){
                newErrors.relation = 'This field is required';
                hasError = true;
            }
        }

        if(readio === 'estate'){
            if(!formValue.fullName1){
                newErrors.fullname1 = 'This field is required';
                hasError = true;
            }
            if(!formValue.address1){
                newErrors.address1 = 'This field is required';
                hasError = true;
            }
            if(!formValue.relation1){
                newErrors.relation1 = 'This field is required';
                hasError = true;
            }
            if(!formValue.number){
                newErrors.number = 'This field is required';
                hasError = true;
            }
            if(!formValue.parcent){
                newErrors.parent = 'This field is required';
                hasError = true;
            }
        }
        if(hasError){
            setErrors(newErrors)
        }else{
            if(readio !== 'undefined'){
                const formData = new FormData();
            if(customerid){
                formData.append('id' , customerid);
            }
            formData.append('customer_id' , localStorage.getItem('authenticateid'));
            formData.append('select_beneficiary', readio);
            formData.append('full_name', formValue.fullName);
            formData.append('address', formValue.address);
            formData.append('relationship', formValue.relation);
            formData.append('beneficiary_number', formValue.number);
            formData.append('beneficiary_full_name', formValue.fullName1);
            formData.append('beneficiary_address', formValue.address1);
            formData.append('beneficiary_relation', formValue.relation1);
            formData.append('share_percent', formValue.parcent);
            try {
                const response = await axios.post(API_ENDPOINTS.ESTATEACTION, formData, {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
                  if (response.status === 200) {
                    setCustomerId('')
                    alert({
                      text: response.data,
                      type: 'success',
                    });
                    navigate('/residual')
                  } else {
                    alert({
                      text: response.data,
                      type: 'error',
                    });
                  }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                  });
            }
            }else{
                alert({
                    text: 'Please Select Any Option',
                    type: 'error',
                  });
            }
            
        }
        


    }

  return (
    <>
        <form  id="estate-gift" onSubmit={handleSubmit}>
                        <h2>Estate And Gift Distribution</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-wrapper">
                                    <label for="">Select Beneficiary*</label>
                                    <div>
                                        <div>
                                            <input type="radio" id="radio-14" name="beneficiary" value="leave" checked={readio === 'leave'} onChange={headle}  />
                                            <label className="tab" for="radio-14">Leave everything to my spouse, or if I
                                                outlive my spouse, to my kids.</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="radio-15" name="beneficiary" value="one_person" checked={readio === 'one_person'} onChange={headle} />
                                            <label className="tab" for="radio-15">Leave everything to just one
                                                person.</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="radio-16" name="beneficiary" value="children" checked={readio === 'children'} onChange={headle} />
                                            <label className="tab" for="radio-16">Leave everything split equally to my
                                                children.</label>
                                        </div>
                                        <div>
                                            <input type="radio" id="radio-17" name="beneficiary" value="estate" checked={readio === 'estate'} onChange={headle}  />
                                            <label className="tab" for="radio-17">Divide up my estate using
                                                percentages.</label>
                                        </div>
                                    </div>
                                </div>
                                {readio === 'one_person' && (
                                    <div className="row">
                                    <div className="col-lg-12">
                                        <div className="input-wrapper">
                                            <label for="">Beneficiary Full Name/Relationship/Address*</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control" name='fullName' value={formValue.fullName} onChange={handleMainInputChange} placeholder="First Name" />
                                            {errors.fullname && <p className="text-danger">{errors.fullname}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <input type="text" className="form-control" placeholder="Address" name='address' value={formValue.address} onChange={handleMainInputChange} />
                                        {errors.address && <p className="text-danger">{errors.address}</p>}
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control" placeholder="Relationship" name='relation' value={formValue.relation} onChange={handleMainInputChange} />
                                            {errors.relation && <p className="text-danger">{errors.relation}</p>}
                                        </div>
                                    </div>
                                </div>
                                )}
                                
                                {readio === 'estate' && (
                                    <div className="row ">
                                    <div className="col-lg-12">
                                        <div className="input-wrapper">
                                            <label for="">Please select the number of beneficiary</label>
                                            <select name="number" id="" defaultValue={formValue.number} onChange={handleMainInputChange} className="form-select">
                                                <option value={'1'}>1</option>
                                                <option value={'2'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                            </select>
                                            {errors.number && <p className="text-danger">{errors.number}</p>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label for="">Beneficiary Full Name/Relationship/Address *</label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control" name='fullName1' value={formValue.fullName1} onChange={handleMainInputChange} placeholder="Full Name" />
                                            {errors.fullname1 && <p className="text-danger">{errors.fullname1}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control"
                                                placeholder="Address" name='address1' value={formValue.address1} onChange={handleMainInputChange} />
                                            {errors.address1 && <p className="text-danger">{errors.address1}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control" name='relation1' value={formValue.relation1} onChange={handleMainInputChange}  placeholder="Relationship" />
                                            {errors.relation1 && <p className="text-danger">{errors.relation1}</p>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-wrapper">
                                            <label for="">Share Percent</label>
                                            <input type="number" className="form-control" name="parcent" value={formValue.parcent} onChange={handleMainInputChange} placeholder="Example 10%" />
                                            {errors.parent && <p className="text-danger">{errors.parent}</p>}
                                        </div>
                                    </div>
                                </div>
                                )}
                                
                            </div>
                        </div>
                        <div>
                            <button type='submit' className="button-style3">Next</button>
                            <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/executors-details')}>Back</span>
                        </div>
                    </form>
    </>
  )
}

export default Estate