import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const [footerData, setFooterData] = useState([]);
    const navigate = useNavigate();
    useEffect(() =>{
        const fetchFooter = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.GETWEBSITEDETAIL('1'));
                setFooterData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchFooter();
    },[])

    const pageChange = (page) => {
        navigate(page);
       }
  return (
    <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-about">
                            <h3 className="text-white">About Will By Lawyers</h3>
                            <p className="text-white">{footerData.about}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-logo-area text-center">
                            <a onClick={() => pageChange('/')}><img src={API_ENDPOINTS.BASE_URL+footerData.footerLogo} loading="lazy"
                                    alt="willbylawyers white logo"/></a>
                            <div className="seprator1"></div>
                            <div className="animated-line"></div>
                            <ul className="social-media justify-content-center mt-4">
                                <li><a href={footerData.facebook}><i className="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href={footerData.instagram}><i className="fa-brands fa-instagram"></i></a></li>
                                <li><a href={footerData.instagram}><i className="fa-brands fa-x-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-contact">
                            <h4 className="text-white">Contacts</h4>
                            <ul>
                                <li>
                                    <span><i className="fa-solid fa-phone"></i></span>
                                    <a href={`tel:${footerData.contact}`}>{footerData.contact}</a>
                                </li>
                                <li>
                                    <span><i className="fa-solid fa-envelope"></i></span>
                                    <a href={`mailto:${footerData.email}`}>{footerData.email}</a>
                                </li>
                                <li>
                                    <span><i className="fa-solid fa-location-dot"></i></span>
                                    <a href="#url">{footerData.address}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-nav">
                <div className="container">
                    <ul>
                        <li><a onClick={() => pageChange('/')} className="active" style={{cursor: "pointer"}} >home</a></li>
                        <li><a onClick={() => pageChange('/about')} className="active" style={{cursor: "pointer"}} >about us</a></li>
                        <li><a onClick={() => pageChange('/pricing')} className="active" style={{cursor: "pointer"}}>pricing</a></li>
                        <li><a onClick={() => pageChange('/faq')} className="active" style={{cursor: "pointer"}} >FAQ</a></li>
                        <li><a onClick={() => pageChange('/contact')} className="active" style={{cursor: "pointer"}} >contact us</a></li>
                    </ul>
                </div>
            </div>
        <p className="text-white text-center mx-auto mb-0">Copyright © 2024 <a href="https://www.matebiz.com/"
                className="text-white" target="_blank">matebiz</a>. All Rights Reserved.</p>
    </footer>

    <button id="goToTopBtn" title="Go to Top">&uarr;</button>
    </>
  )
}

export default Footer