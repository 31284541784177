import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../../../config/apiConfig';
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import { useNavigate } from 'react-router-dom';
function Pets() {
    const navigate = useNavigate();
    const [radio, setRadio] = useState('no');
    const [maintenance, setMaintenance] = useState('no')
    const [mainid, setMainId] = useState('');
    const [pets, setPets] = useState([]);
    const [memberid, setMemberid] = useState('');
    const [status, setStatus] = useState('initial');
    const [modalform, setModalForm] = useState({
        petName: '',
        petDescription: '',
        petGuardianName: '',
        petGuardianRelation: '',
        petGuardianAddress: '',
        petMaintenace: 'no'
    });

    const [errors, setErrors] = useState({
        petName: '',
        petDescription: '',
        petGuardianName: '',
        petGuardianRelation: '',
        petGuardianAddress: '',
        petMaintenace: ''
    })

    const [colorMapping, setColorMapping] = useState({
        A: '#F0F8FF', // AliceBlue
        B: '#F5F5DC', // Beige
        C: '#FFF8DC', // Cornsilk
        D: '#E9967A', // DarkSalmon
        E: '#F0EAD6', // Eggshell
        F: '#FFFAF0', // FloralWhite
        G: '#DCDCDC', // Gainsboro
        H: '#F0FFF0', // Honeydew
        I: '#FFFFF0', // Ivory
        J: '#F8DE7E', // Jasmine
        K: '#F0E68C', // Khaki
        L: '#E6E6FA', // Lavender
        M: '#F5FFFA', // MintCream
        N: '#FFDEAD', // NavajoWhite
        O: '#FDF5E6', // OldLace
        P: '#FFDAB9', // PeachPuff
        Q: '#E8D7A1', // Quartz (custom color)
        R: '#BC8F8F', // RosyBrown
        S: '#FFF5EE', // Seashell
        T: '#D8BFD8', // Thistle
        U: '#FFDDCA', // UnbleachedSilk (custom color)
        V: '#F3E5AB', // Vanilla
        W: '#F5F5F5', // WhiteSmoke
        X: '#738678', // Xanadu (Note: Xanadu is not a light color, but this is the closest match)
        Y: '#9ACD32', // YellowGreen
        Z: '#E5E5E5', // ZincWhite (custom color) // Custom name, as there's no Z color in standard CSS
    });
    useEffect(() => {
        const fetchMainData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.PARSONALDETAILS(localStorage.getItem('authenticateid')))
                if(response.status === 200){
                  const data = response.data;
                  if(data !== ''){
                    setMainId(data.id);
                    if(data.do_you_pets !== '' && data.do_you_pets !== null){
                        setRadio(data.do_you_pets)
                    }
                  }
        
                 
                }
              } catch (error) {
                console.log(error)
              }
        }
        const fetchPets = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PETLISTS(localStorage.getItem('authenticateid')));
                setPets(response.data)
            } catch (error) {
                console.log(error);
            }
        }
        fetchMainData();
        fetchPets();
    }, [status])

    const radioButton = (event) => {
        setMaintenance(event.target.value);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalForm((prevFamily) => ({
            ...prevFamily,
            [name]: value,
        }));

    };

    const handlePets = (event) => {
        setRadio(event.target.value);
    }

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this Pet')) {
            try {
                const res = await axios.get(API_ENDPOINTS.PETSDELETE(id));
                if (res.status === 200) {
                    setStatus(prev => !prev);
                    alert({
                        text: res.data.message,
                        type: 'success',
                    });
                } else {
                    alert({
                        text: res.data.error,
                        type: 'error',
                    });
                }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                });
            }
        }

    }

    const handleEdit = async (id) => {
        document.getElementById('open-modal-button').click();
        try {
            const response = await axios.get(API_ENDPOINTS.PETSDETAILS(id));
            const data = response.data;
            setMemberid(data.id)
            setModalForm({
                petName: data.pet_name,
                petDescription: data.pet_description,
                petGuardianName: data.pet_guardian_name,
                petGuardianRelation: data.pet_guardian_relation,
                petGuardianAddress: data.pet_guardian_address,
                petMaintenace: data.pet_maintenance
            });
            setMaintenance(data.pet_maintenance)


        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModal = () => {
        setMemberid('')
        setMaintenance('no')
                    setModalForm({
                        petName: '',
                        petDescription: '',
                        petGuardianName: '',
                        petGuardianRelation: '',
                        petGuardianAddress: '',
                        petMaintenace: 'no'
                    });
      }

    const addPetsFunction = async () => {
        let hasError = false;
        const newErrors = {
            petName: '',
            petDescription: '',
            petGuardianName: '',
            petGuardianRelation: '',
            petGuardianAddress: '',
            petMaintenace: ''
        }

        if (!modalform.petName) {
            newErrors.petName = 'This field is required';
            hasError = true;
        }
        if (!modalform.petDescription) {
            newErrors.petDescription = 'This field is required';
            hasError = true;
        }
        if (!modalform.petGuardianName) {
            newErrors.petGuardianName = 'This field is required';
            hasError = true;
        }
        if (!modalform.petGuardianAddress) {
            newErrors.petGuardianAddress = 'This field is required';
            hasError = true;
        }
        if (!modalform.petGuardianRelation) {
            newErrors.petGuardianRelation = 'This field is required';
            hasError = true;
        }
        if (!maintenance) {
            newErrors.petMaintenace = 'This field is required';
            hasError = true;
        }
        if (hasError) {
            setErrors(newErrors)
        } else {
            const formData = new FormData();
            if(memberid !== ''){
                formData.append('id', memberid);
            }
            formData.append('customer_id', localStorage.getItem('authenticateid'));
            formData.append('pet_name', modalform.petName)
            formData.append('pet_description', modalform.petDescription)
            formData.append('pet_guardian_name', modalform.petGuardianName)
            formData.append('pet_guardian_relation', modalform.petGuardianRelation)
            formData.append('pet_guardian_address', modalform.petGuardianAddress)
            formData.append('pet_maintenance', maintenance)
            try {
                const response = await axios.post(API_ENDPOINTS.PETSACTION, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    setStatus(prev => !prev);
                    document.getElementById('close-modal-button').click();
                    alert({
                        text: response.data,
                        type: 'success',
                    });
                    if(memberid !== ''){
                        setMemberid('');
                    }
                    setMaintenance('no')
                    setModalForm({
                        petName: '',
                        petDescription: '',
                        petGuardianName: '',
                        petGuardianRelation: '',
                        petGuardianAddress: '',
                        petMaintenace: 'no'
                    });

                } else {
                    alert({
                        text: response.data,
                        type: 'error',
                    });
                }
            } catch (error) {
                alert({
                    text: error,
                    type: 'error',
                });
            }
        }

    }

    const finalSubmit = async (event) =>{
        event.preventDefault();
        let hasError = false;
        if(radio !== '' && radio !== null){
            const formData = new FormData();
            if(mainid){
                formData.append('id', mainid);
              }
            formData.append('customer_id', localStorage.getItem('authenticateid'));
            formData.append('do_you_pets', radio);
            try {
                const response = await axios.post(API_ENDPOINTS.PARSONALACTION, formData, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                if (response.status === 200) {
                  
                  alert({
                    text: response.data,
                    type: 'success',
                  });
                  navigate('/assets-liabilities')
                } else {
                  alert({
                    text: response.data,
                    type: 'error',
                  });
                }
                
              } catch (error) {
                alert({
                  text: error,
                  type: 'error',
                });
              }
        }else{
            alert({
                text: 'Please Check Any One',
                type: 'error',
            });
        }
    }

    return (
        <>
            <form action="" id="pet-details" onSubmit={finalSubmit}>
                <h2>Pets Details</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="input-wrapper">
                            <label for="">Do You have pets? *</label>
                            <div>
                                <input type="radio" id="radio-22" name="pet-age" value="yes" checked={radio === 'yes'} onChange={handlePets} />
                                <label className="tab" for="radio-22">Yes</label>
                                <input type="radio" id="radio-23" name="pet-age" value="no" checked={radio === 'no'} onChange={handlePets} />
                                <label className="tab" for="radio-23">No</label>
                            </div>
                            {radio === 'yes' && (
                                <>
                                {pets.length > 0 && (
                                    <>
                                        <h5>My Substitute Executor...</h5>

                                        {pets.map((member, index) => (
                                            <div key={member.id} style={{ backgroundColor: 'white', padding: '10px', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }} className="d-flex p-3 border rounded">

                                                <div className="rounded-circle d-flex justify-content-center" style={{ backgroundColor: colorMapping[member.pet_name[0].toUpperCase()], width: '50px', height: '50px', padding: '10px' }}>{member.pet_name[0].toUpperCase()}</div>

                                                <div>{member.pet_name} <br />
                                                    {/* <button type="button" style={{ paddingLeft: '4px', paddingRight: '4px', border: 'none', borderRadius: '2px', fontSize: '10px' }}>{member.relation}</button> */}
                                                </div>
                                                <div><span onClick={() => handleDelete(member.id)} style={{ cursor: 'pointer' }}><i class="fa-solid fa-circle-minus"></i> Remove</span></div>
                                                <div><span style={{ cursor: 'pointer' }} onClick={() => handleEdit(member.id)}><i class="fa-solid fa-pen"></i> Edit</span></div>
                                            </div>
                                        ))}

                                    </>
                                )}
                               
                                <div className="">
                                {pets.length < 2 && (
                                    <div className="new-child">
                                        <div className="add-child">
                                            <p className="mb-0">None Selected</p>
                                            <a href="javascript:void(0)" className="button-style1"
                                                data-bs-toggle="modal" data-bs-target="#add-pet-details" id="open-modal-button" onClick={handleOpenModal}><i
                                                    className="fa-solid fa-plus"></i> Add Pets Details</a>
                                        </div>

                                        {/* <!-- Modal --> */}
                                        <div className="modal fade" id="add-pet-details" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="exampleModalLabel"> Add
                                                            Pets Details
                                                        </h1>
                                                        <button type="button" className="btn-close"
                                                            data-bs-dismiss="modal" aria-label="Close" id="close-modal-button"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Pet Name</label>
                                                                    <input type="text" className="form-control" name='petName' value={modalform.petName} onChange={handleInputChange} />
                                                                    {errors.petName && <p className="text-danger">{errors.petName}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Pet Description</label>
                                                                    <textarea name="petDescription" className="form-control"
                                                                        id="" value={modalform.petDescription} onChange={handleInputChange} />
                                                                    {errors.petDescription && <p className="text-danger">{errors.petDescription}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Pet guardian full name
                                                                        *</label>
                                                                    <input type="text" className="form-control" name='petGuardianName' value={modalform.petGuardianName} onChange={handleInputChange} />
                                                                    {errors.petGuardianName && <p className="text-danger">{errors.petGuardianName}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Pet guardian relationship
                                                                        *</label>
                                                                    <textarea name="petGuardianRelation" className="form-control"
                                                                        id="" value={modalform.petGuardianRelation} onChange={handleInputChange} />
                                                                    {errors.petGuardianRelation && <p className="text-danger">{errors.petGuardianRelation}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Pet guardian address</label>
                                                                    <textarea name="petGuardianAddress" className="form-control"
                                                                        id="" value={modalform.petGuardianAddress} onChange={handleInputChange} />
                                                                    {errors.petGuardianAddress && <p className="text-danger">{errors.petGuardianAddress}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <label for="">Do you wish to leave a one
                                                                        time sum to be used for future care,
                                                                        feeding and maintenance of this
                                                                        pet</label>
                                                                    <div>
                                                                        <input type="radio" id="radio24"
                                                                            name="maintenance" value="yes" checked={maintenance === 'yes'} onChange={radioButton} />
                                                                        <label className="tab"
                                                                            for="radio24">Yes</label>
                                                                        <input type="radio" id="radio25"
                                                                            name="maintenance" value="no" checked={maintenance === 'no'} onChange={radioButton} />
                                                                        <label className="tab"
                                                                            for="radio25">No</label>
                                                                        {errors.petMaintenace && <p className="text-danger">{errors.petMaintenace}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <span className="button-style1" style={{ cursor: 'pointer' }} onClick={addPetsFunction}>Add</span>
                                                        {/* <button className="button-style1">Cancel</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                   
                                </div>
                                </>
                            )}

                        </div>
                    </div>

                </div>
                <div>
                <button type='submit' className="button-style3">Next</button>
                <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/residual')}>Back</span>
                </div>
            </form>
        </>
    )
}

export default Pets