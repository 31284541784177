import axios from 'axios';
import React, { useEffect, useState } from 'react'
import API_ENDPOINTS from '../config/apiConfig';
import MyComponent from '../components/MyComponent';

function Contact() {
    const [sections , setSection] = useState([]);
    const [website, setWebsite] = useState([]);
    const [loding, setLoding] = useState(false);
    const [formValue, setFormValue] = useState({
       firstName: '',
       lastName: '',
       email: '',
       phone: '',
       message: '' 
    });

    const [error, setError] = useState({
        firstName: '',
        email: '',
        message: '',
    });

    const [mainError, setMainError] = useState({
        class: '',
        text: '',
    });
    useEffect(()  =>{
        const fetchData = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.PAGESSECTIONS('5'));
                setSection(response.data);
                const res = await axios.get(API_ENDPOINTS.GETWEBSITEDETAIL('1'));
                setWebsite(res.data);
            } catch (error) {
                console.log(error)
            }
        }
        

        fetchData();
    },[])

    const handlemail = async (event) => {
        event.preventDefault();
        setLoding(true)
        let hasError = false;
        const newErrors = { firstName: '', lastName:'', email:'' , phone: '', message: '', submit: ''};
        if(!formValue.firstName){
            newErrors.firstName = 'This field is required';
            hasError = true;
        }
        if(!formValue.email){
            newErrors.email = 'This field is required';
            hasError = true;
        }
        if(!formValue.message){
            newErrors.message = 'This field is required';
            hasError = true;
        }
        if(hasError){
            setError(newErrors);
        }else{
            const formData = new FormData();
            formData.append('first', formValue.firstName);
            formData.append('last', formValue.lastName);
            formData.append('email', formValue.email);
            formData.append('phone', formValue.phone);
            formData.append('message', formValue.message);
            try {
                
                const response = await axios.post(API_ENDPOINTS.INQUIRY, formData , {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if(response.status === 200){
                    
                    setMainError({
                        class:'alert alert-success',
                        text: 'Thank you for Inquiry!'
                    })

                    setFormValue({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        message: ''
                    })
                    
                }else{
                    setMainError({
                        class:'alert alert-danger',
                        text: 'Server Error....'
                    })
                    
                }
                
                
            } catch (error) {
                setMainError({
                    class:'alert alert-danger',
                    text: 'Server Error....'
                })
            } finally {
                setLoding(false)
            }
        }
    }


  return (
    <>
      <main>
          <section className="breadcrum-section">
              <div className="container">
                  <h1 className="text-white">Contact Us</h1>
                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><a href="/" className="text-white">Home</a></li>
                          <li className="breadcrumb-item active text-white" aria-current="page">Contact Us</li>
                      </ol>
                  </nav>
              </div>
          </section>
          <section className="contact-details section-padding">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                          <div className="inner">
                              <i className="fa-solid fa-phone"></i>
                              <h4>Phone</h4>
                              {/* <p>Reach out to us directly by phone for any inquiries or support.</p> */}
                              <a href={`tel:${website.contact}`}>{website.contact}</a>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                          <div className="inner">
                              <i className="fa-solid fa-envelope"></i>
                              <h4>Email</h4>
                              {/* <p>Feel free to drop us an email for any questions or further information.</p> */}
                              <a href={`mailto:${website.email}`}>{website.email}</a>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                          <div className="inner">
                              <i className="fa-solid fa-location-dot"></i>
                              <h4>Location</h4>
                              {/* <p>Visit us at our office for a personal consultation or support.</p> */}
                              <a href="javascript:void(0)">{website.address}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="contact-form section-padding pb-0">
              <div className="container">
                  <div className="row">
                      {sections.map((section , index) => (
                        <>
                        {section.name === 'conact' && (
                            <div className="col-lg-5">
                          <div className="contact-sidebar">
                              <aside>
                                  <div className="contact-upper">
                                      <img src="assets/images/twenty-four-hour.webp" className="clock" loading="lazy"
                                          alt="twenty four hour" />
                                      <h4>{section.headline}</h4>
                                  </div>
                                  <p className="text-white">
                                    <MyComponent content={section.description} />
                                  </p>
                                  <div className="image-wrapper">
                                      <img src={API_ENDPOINTS.BASE_URL+section.image} loading="lazy"
                                          alt="contact person image" />
                                  </div>
                                  <ul className="social-media justify-content-center mt-4">
                                      <li><a href={website.facebook}><i className="fa-brands fa-facebook-f"
                                                  aria-hidden="true"></i></a></li>
                                      <li><a href={website.instagram}><i className="fa-brands fa-instagram"
                                                  aria-hidden="true"></i></a></li>
                                      <li><a href={website.twitter}><i className="fa-brands fa-x-twitter"
                                                  aria-hidden="true"></i></a></li>
                                  </ul>
                              </aside>
                          </div>
                      </div>  
                        )}
                        </>
                      ))}
                     
                      <div className="col-lg-6">
                          <div className="form-area">
                              <div className="section-heading">
                                  <h2>Leave us your info</h2>
                                  <p>and we will get back to you.</p>
                                  <div className={mainError.class}>{mainError.text}</div>
                                  <form onSubmit={handlemail}>
                                      <div className="row">
                                          <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                  <input type="text" className="form-control" name="firstName" value={formValue.firstName} placeholder="First Name" onChange={(e) => setFormValue((prev) => ({...prev,firstName:e.target.value}))} />
                                                  {error.firstName && <p style={{ color: 'red' }}>{error.firstName}</p>}
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                  <input type="text" className="form-control" name='lastName' value={formValue.lastName} placeholder="Last Name (Optional)" onChange={(e) => setFormValue((prev) => ({...prev,lastName:e.target.value}))} />
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                  <input type="email" className="form-control" name="email" value={formValue.email} placeholder="Email" onChange={(e) => setFormValue((prev) => ({...prev,email:e.target.value}))} />
                                                  {error.email && <p style={{ color: 'red' }}>{error.email}</p>}
                                              </div>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                  <input type="phone" className="form-control" placeholder="Phone (Optional)" name='phone' value={formValue.phone} onChange={(e) => setFormValue((prev) => ({...prev,phone:e.target.value}))} />
                                              </div>
                                          </div>
                                          <div className="col-12">
                                              <div className="input-wrapper">
                                                  <textarea name="message" id="" className="form-control"
                                                      placeholder="Message" value={formValue.message} onChange={(e) => setFormValue((prev) => ({...prev,message:e.target.value}))}/>
                                                      {error.message && <p style={{ color: 'red' }}>{error.message}</p>}
                                              </div>
                                          </div>
                                          <div className="col-12">
                                              <div className="input-wrapper">
                                                  <button type="submit" className="button-style1" disabled={loding}>Submit <i
                                                          className="fa-regular fa-paper-plane"></i></button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="map">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.96674143591!2d77.14845247550294!3d28.690641475633097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d019156516583%3A0x5c781e0cb68784d0!2zTUFURUJJWiDvuI8!5e0!3m2!1sen!2sin!4v1719835051454!5m2!1sen!2sin"
                  width="100%" style={{border:"0"}} allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
          </section>

      </main>
    </>
  )
}

export default Contact