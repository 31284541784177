import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import API_ENDPOINTS from '../../../config/apiConfig';

function PricingForm() {
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const [amount, setAmount] = useState(0)
    const [selectPlan, setSelectPlan] = useState('')

    useEffect(() => {
        const fetchPackageDropdown = async () =>{
            try {
                const response = await axios.get(API_ENDPOINTS.PLANSLIST);
                setPackages(response.data)
            } catch (error) {
                console.error(error);
            }
        }

        fetchPackageDropdown();
    },[])

    const handleChangePlan = async (event) =>{
        if(event.target.value !== ''){
            try {
                const response = await axios.get(API_ENDPOINTS.PLANDETAIL(event.target.value));
                setAmount(response.data.amount)
            } catch (error) {
                console.error(error);
            }
        }else{
            setAmount(0);  
        }
    }

  return (
    <>
        <form action="" id="assets-liabilities">
                            <h2>Choose your pricing plan</h2>
                            <div className="row">
                                <div className="col-12">
                                    <div className="input-wrapper">
                                        <label for="" className="form-label">Choose your pricing plan</label>
                                        <select name="selectPlan" defaultValue={selectPlan} onChange={handleChangePlan} className="form-select" id="">
                                            <option value={''}>Select Plan</option>
                                            {packages.map((plan, index) => (
                                                <option value={plan.id}>{plan.name.toUpperCase()} - {plan.validity.toUpperCase()}(${plan.amount}) </option>
                                            ))}
                                            {/* <option value="">Premium Package - Single ($169)</option>
                                            <option value="">Premium Package - Lawyer Consultation Included ($259 for
                                                couple)
                                                </option> */}
                                        </select>
                                        <small>If you have chosen Couple pricing Plan than we will send a email to your
                                            spouse with instruction on how to proceed with the Will inputs. If you have
                                            chosen Premium package ( Single and Couple) than we will send you email and
                                            /or call you to book the lawyer's appointment</small>
                                    </div>
                                    {amount !== 0 && (
                                        <div className="input-wrapper">
                                            <p>Total Amount</p>
                                            <p>$ {amount}</p>
                                        </div>
                                    )}
                                    
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-wrapper">
                                        <input type="number" placeholder="Card Number" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-wrapper">
                                        <input type="number" placeholder="MM / YY CVC" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-wrapper">
                                        <input type="number" placeholder="Name on Card" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a href="javascript:void(0)" className="button-style3">Next</a>
                                <span style={{ cursor: 'pointer' }} className="button-style3 fade-className" onClick={() => navigate('/message')}>Back</span>
                            </div>
                        </form>
    </>
  )
}

export default PricingForm