import axios from "axios";
import React, { useEffect, useState } from "react";
import API_ENDPOINTS from "../../../config/apiConfig";
import { alert } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const navigate = useNavigate();
  const [readio, setRedio] = useState("option1");
  const [members, setMembers] = useState([]);
  const [readiounder, setRediounder] = useState("yes");
  const [differentname, setDifferentName] = useState("no");
  const [differentdiscount, setDifferentDiscount] = useState("yes");
  const [children, setChildren] = useState("yes");
  const [status, setStatus] = useState('initial');
  const [update, setUpdate] = useState('')
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [married, setMarried] = useState('');
  const [customerid, setCustomerId] = useState('')
  const [family, setFamily] = useState({
    firstName: "",
    midelName: "",
    lastName: "",
    relation: "",
    dob: "",
    area_code: "",
    contact: "",
    email: "",
    street: "",
    suburb: "",
    state: "",
    postcode: "",
    country: "",
    age: "",
  });

  const [colorMapping, setColorMapping] = useState({
    A: '#F0F8FF', // AliceBlue
    B: '#F5F5DC', // Beige
    C: '#FFF8DC', // Cornsilk
    D: '#E9967A', // DarkSalmon
    E: '#F0EAD6', // Eggshell
    F: '#FFFAF0', // FloralWhite
    G: '#DCDCDC', // Gainsboro
    H: '#F0FFF0', // Honeydew
    I: '#FFFFF0', // Ivory
    J: '#F8DE7E', // Jasmine
    K: '#F0E68C', // Khaki
    L: '#E6E6FA', // Lavender
    M: '#F5FFFA', // MintCream
    N: '#FFDEAD', // NavajoWhite
    O: '#FDF5E6', // OldLace
    P: '#FFDAB9', // PeachPuff
    Q: '#E8D7A1', // Quartz (custom color)
    R: '#BC8F8F', // RosyBrown
    S: '#FFF5EE', // Seashell
    T: '#D8BFD8', // Thistle
    U: '#FFDDCA', // UnbleachedSilk (custom color)
    V: '#F3E5AB', // Vanilla
    W: '#F5F5F5', // WhiteSmoke
    X: '#738678', // Xanadu (Note: Xanadu is not a light color, but this is the closest match)
    Y: '#9ACD32', // YellowGreen
    Z: '#E5E5E5', // ZincWhite (custom color) // Custom name, as there's no Z color in standard CSS
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    relation: '',
    dob: '',
    area_code: '',
    contact: '',
    email: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    age: '',
  });

  const [formValue, setFormValue] = useState({
    title: 'mr',
    firstName: '',
    midName: '',
    lastName: '',
    otherFirstName: '',
    othermidName: '',
    otherlastName: '',
    email: '',
    phoneCode: '',
    contact: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    pin: '',
    dob: '',
    status: '',
    spouseEmail: '',
    spousefirstName: '',
    spousemidName: '',
    spouselastName: ''

  })

  const [error, setError] = useState({
        title: '',
        firstName: '',
        lastName: '',
        otherFirstName: '',
        otherlastName: '',
        email: '',
        phoneCode: '',
        contact: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pin: '',
        dob: '',
        status: '',
        spouseEmail: '',
        spousefirstName: '',
        spouselastName: ''
  })

  useEffect(() => {
    const familyMembers = async () => {
      try {
        const res = await axios.get(API_ENDPOINTS.FAMILYMEMBERS(localStorage.getItem('authenticateid')));
        setMembers(res.data);
      } catch (error) {
        console.log(error)
      }
    }
    const fetchCountries = async () => {
      try {
        const res = await axios.get(API_ENDPOINTS.COUNTRIES);
        setCountries(res.data);
      } catch (error) {
        console.log(error)
      }
    }
    const fetchDetail = async () =>{
      try {
        const response = await axios.get(API_ENDPOINTS.PARSONALDETAILS(localStorage.getItem('authenticateid')))
        if(response.status === 200){
          const data = response.data;
          console.log(data)
          setCustomerId(data.id)
          const date = new Date(data.date_of_birth);
        const formattedDate = date.getFullYear() + '-' + 
                              String(date.getMonth() + 1).padStart(2, '0') + '-' + 
                              String(date.getDate()).padStart(2, '0');
            setFormValue({
              title: data.title,
              firstName: data.first_name,
              midName: data.middle_name,
              lastName: data.last_name,
              otherFirstName: data.different_first_name,
              othermidName: data.different_middle_name,
              otherlastName: data.different_last_name,
              email: data.email_address,
              phoneCode: data.phone_code,
              contact: data.contact_no,
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              state: data.state,
              pin: data.zip,
              dob: formattedDate,
              status: data.marital_status,
              spouseEmail: data.spouse_email_address,
              spousefirstName: data.spouse_first_name,
              spousemidName: data.spouse_middle_name,
              spouselastName: data.spouse_last_name
          
            })
            setMarried(data.marital_status)
            setRedio(data.resident_age === 'yes' ? 'option1' : 'option2');
            setDifferentName(data.different_name)
            setDifferentDiscount(data.spouse_couple_package)
            setChildren(data.do_you_children)

         
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchCountries();
    familyMembers();
    fetchDetail();
  }, [status])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFamily((prevFamily) => ({
      ...prevFamily,
      [name]: value,
    }));
    if (name === 'country') {
      statesGet(value)
    }

  };

  const handleInputMainChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevFamily) => ({
      ...prevFamily,
      [name]: value,
    }));


  };

  const statesGet = async (id) => {
    try {
      const res = await axios.get(API_ENDPOINTS.STATES(id));
      setStates(res.data);

    } catch (error) {
      console.log(error)
    }
  }

  const [isVisible, setIsVisible] = useState({
    dob: false,
    phone: false,
    email: false,
    residential: false,
    joinwill: false,
  });

  const headleAge = (event) => {
    setRedio(event.target.value);
  };

  const handleMarried = (event) => {
    setMarried(event.target.value)
  }
  const headlename = (event) => {
    setDifferentName(event.target.value);
  };

  const headlediscount = (event) => {
    setDifferentDiscount(event.target.value);
  };

  const headlechildren = (event) => {
    setChildren(event.target.value);
  };

  const handleunderage = (event) => {
    setRediounder(event.target.value)
  }

  const handleToggle = (event) => {
    // console.log(event.target)
    const { id, checked } = event.target;
    if (id === "joinwill") {
      setIsVisible((prevState) => ({
        ...prevState,
        [id]: checked,
        email: checked, // Reflect email visibility with joinwill
      }));
      setRediounder("no");
    } else {
      setIsVisible((prevState) => ({
        ...prevState,
        [id]: checked,
      }));
    }
  };

  const addFamilyMember = async () => {

    let hasError = false;
    const newErrors = {
      firstName: '',
      lastName: '',
      relation: '',
      dob: '',
      area_code: '',
      contact: '',
      email: '',
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
      age: '',
    }

    if (!family.firstName) {
      newErrors.firstName = 'This field is required';
      hasError = true;
    }
    if (!family.lastName) {
      newErrors.lastName = 'This field is required';
      hasError = true;
    }
    if (!family.relation) {
      newErrors.relation = 'This field is required';
      hasError = true;
    }

    if (isVisible.dob) {
      if (!family.dob) {
        newErrors.dob = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.phone) {
      // if(!family.area_code){
      //     newErrors.area_code = 'This field is required';
      //     hasError = true;
      // }
      if (!family.contact) {
        newErrors.contact = 'This field is required';
        hasError = true;
      }
    }

    if (isVisible.email) {
      if (!family.email) {
        newErrors.email = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.joinwill) {
      if (!family.email) {
        newErrors.email = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.residential) {
      if (!family.street) {
        newErrors.street = 'This field is required';
        hasError = true;
      }
      if (!family.suburb) {
        newErrors.suburb = 'This field is required';
        hasError = true;
      }
      if (!family.state) {
        newErrors.state = 'This field is required';
        hasError = true;
      }
      if (!family.postcode) {
        newErrors.postcode = 'This field is required';
        hasError = true;
      }
      if (!family.country) {
        newErrors.country = 'This field is required';
        hasError = true;
      }
    }
    if (hasError) {
      setErrors(newErrors);
      console.log(JSON.stringify(errors, null, 2));
    } else {
      if ((!isVisible.dob && !isVisible.phone) && (!isVisible.email && !isVisible.residential)) {
        alert('Please Method of verification Choose at least 1');
      } else {
        const formData = new FormData();
        formData.append('customer_id', localStorage.getItem('authenticateid'));
        formData.append('first_name', family.firstName)
        formData.append('middle_name', family.midelName)
        formData.append('last_name', family.lastName)
        formData.append('join_will', isVisible.joinwill ? 1 : 0)
        formData.append('dob_option', isVisible.dob ? 1 : 0)
        formData.append('dob', family.dob)
        formData.append('relation', family.relation)
        formData.append('phone_option', isVisible.phone ? 1 : 0)
        formData.append('phone_code', family.area_code)
        formData.append('phone_number', family.contact)
        formData.append('email_option', isVisible.email ? 1 : 0)
        formData.append('email_address', family.email)
        formData.append('residential_address_option', isVisible.residential ? 1 : 0)
        formData.append('state', family.state)
        formData.append('zip_code', family.postcode)
        formData.append('country', family.country)
        formData.append('contact_under_age', readiounder ? 1 : 0)
        formData.append('street', family.street)
        formData.append('suburb', family.suburb)
        // console.log(Object.fromEntries(formData.entries()));
        // console.log(JSON.stringify(family, null, 2));

        try {
          const response = await axios.post(API_ENDPOINTS.FAMILYADD, formData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (response.status === 200) {
            setStatus(prev => !prev);
            document.getElementById('close-modal-button').click();
            alert({
              text: response.data,
              type: 'success',
            });
            setFamily({
              firstName: "",
              midelName: "",
              lastName: "",
              relation: "",
              dob: "",
              area_code: "",
              contact: "",
              email: "",
              street: "",
              suburb: "",
              state: "",
              postcode: "",
              country: "",
              age: "",
            });
            setIsVisible({
              dob: false,
              phone: false,
              email: false,
              residential: false,
              joinwill: false,
            });

          } else {
            alert({
              text: response.data,
              type: 'error',
            });
          }

        } catch (error) {
          alert({
            text: error,
            type: 'error',
          });
        } 
      }



    }


  };

  const updateFamilyMember = async () => {

    let hasError = false;
    const newErrors = {
      firstName: '',
      lastName: '',
      relation: '',
      dob: '',
      area_code: '',
      contact: '',
      email: '',
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
      age: '',
    }

    if (!family.firstName) {
      newErrors.firstName = 'This field is required';
      hasError = true;
    }
    if (!family.lastName) {
      newErrors.lastName = 'This field is required';
      hasError = true;
    }
    if (!family.relation) {
      newErrors.relation = 'This field is required';
      hasError = true;
    }

    if (isVisible.dob) {
      if (!family.dob) {
        newErrors.dob = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.phone) {

      if (!family.contact) {
        newErrors.contact = 'This field is required';
        hasError = true;
      }
    }

    if (isVisible.email) {
      if (!family.email) {
        newErrors.email = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.joinwill) {
      if (!family.email) {
        newErrors.email = 'This field is required';
        hasError = true;
      }
    }
    if (isVisible.residential) {
      if (!family.street) {
        newErrors.street = 'This field is required';
        hasError = true;
      }
      if (!family.suburb) {
        newErrors.suburb = 'This field is required';
        hasError = true;
      }
      if (!family.state) {
        newErrors.state = 'This field is required';
        hasError = true;
      }
      if (!family.postcode) {
        newErrors.postcode = 'This field is required';
        hasError = true;
      }
      if (!family.country) {
        newErrors.country = 'This field is required';
        hasError = true;
      }
    }
    if (hasError) {
      setErrors(newErrors);
      console.log(JSON.stringify(errors, null, 2));
    } else {
      if ((!isVisible.dob && !isVisible.phone) && (!isVisible.email && !isVisible.residential)) {
        alert('Please Method of verification Choose at least 1');
      } else {
        const formData = new FormData();
        formData.append('id', update)
        // formData.append('customer_id',localStorage.getItem('authenticateid'));
        formData.append('first_name', family.firstName)
        formData.append('middle_name', family.midelName)
        formData.append('last_name', family.lastName)
        formData.append('join_will', isVisible.joinwill ? 1 : 0)
        formData.append('dob_option', isVisible.dob ? 1 : 0)
        formData.append('dob', family.dob)
        formData.append('relation', family.relation)
        formData.append('phone_option', isVisible.phone ? 1 : 0)
        formData.append('phone_code', family.area_code)
        formData.append('phone_number', family.contact)
        formData.append('email_option', isVisible.email ? 1 : 0)
        formData.append('email_address', family.email)
        formData.append('residential_address_option', isVisible.residential ? 1 : 0)
        formData.append('state', family.state)
        formData.append('zip_code', family.postcode)
        formData.append('country', family.country)
        formData.append('contact_under_age', readiounder ? 1 : 0)
        formData.append('street', family.street)
        formData.append('suburb', family.suburb)
        // console.log(Object.fromEntries(formData.entries()));
        // console.log(JSON.stringify(family, null, 2));

        try {
          const response = await axios.post(API_ENDPOINTS.FAMILYUPDATE, formData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (response.status === 200) {
            setStatus(prev => !prev);
            document.getElementById('close-modal-button').click();
            setUpdate('')
            alert({
              text: response.data,
              type: 'success',
            });
            setFamily({
              firstName: "",
              midelName: "",
              lastName: "",
              relation: "",
              dob: "",
              area_code: "",
              contact: "",
              email: "",
              street: "",
              suburb: "",
              state: "",
              postcode: "",
              country: "",
              age: "",
            });
            setIsVisible({
              dob: false,
              phone: false,
              email: false,
              residential: false,
              joinwill: false,
            });

          } else {
            alert({
              text: response.data,
              type: 'error',
            });
          }

        } catch (error) {
          alert({
            text: error,
            type: 'error',
          });
        }
      }



    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this Family Member')) {
      try {
        const res = await axios.get(API_ENDPOINTS.FAMILYMEMBERDELETE(id));
        if (res.status === 200) {
          setStatus(prev => !prev);
          alert({
            text: res.data.message,
            type: 'success',
          });
        } else {
          alert({
            text: res.data.error,
            type: 'error',
          });
        }
      } catch (error) {
        alert({
          text: error,
          type: 'error',
        });
      }
    }

  }

  const handleEdit = async (id) => {
    document.getElementById('open-modal-button').click();
    try {
      const response = await axios.get(API_ENDPOINTS.FAMILYMEMBERDETAILS(id));
      const data = response.data;
      setUpdate(data.id)
      setIsVisible({
        dob: data.dob_option == 1 ? true : false,
        phone: data.phone_option == 1 ? true : false,
        email: data.email_option == 1 ? true : false,
        residential: data.residential_address_option == 1 ? true : false,
        joinwill: false,
      });
      setFamily({
        firstName: data.first_name,
        midelName: data.middle_name,
        lastName: data.last_name,
        relation: data.relation,
        dob:  new Date(data.dob).toISOString().split('T')[0],
        area_code: data.phone_code,
        contact: data.phone_number,
        email: data.email_address,
        street: data.street,
        suburb: data.suburb,
        state: data.state,
        postcode: data.zip_code,
        country: data.country,
        age: data.contact_under_age === 1 ? 'yes' : "no",
      });


    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = () => {
    setFamily({
      firstName: "",
      midelName: "",
      lastName: "",
      relation: "",
      dob: "",
      area_code: "",
      contact: "",
      email: "",
      street: "",
      suburb: "",
      state: "",
      postcode: "",
      country: "",
      age: "",
    });
    setIsVisible({
      dob: false,
      phone: false,
      email: false,
      residential: false,
      joinwill: false,
    });
  }

  const handleMainSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    let hasError = false;
    const newErrors = {
        title: '',
        firstName: '',
        lastName: '',
        otherFirstName: '',
        otherlastName: '',
        email: '',
        phoneCode: '',
        contact: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pin: '',
        dob: '',
        status: '',
        spouseEmail: '',
        spousefirstName: '',
        spouselastName: ''
    }

    if(!formValue.firstName){
      newErrors.firstName = 'This field is required';
      hasError = true;
    }
    if(!formValue.lastName){
      newErrors.lastName = 'This field is required';
      hasError = true;
    }
    if(!formValue.title){
      newErrors.title = 'This field is required';
      hasError = true;
    }
    if(differentname === 'yes'){
      if(!formValue.otherFirstName){
        newErrors.otherFirstName = 'This field is required';
        hasError = true;
      }
      if(!formValue.otherlastName){
        newErrors.otherlastName = 'This field is required';
        hasError = true;
      }
    }
    
    if(!formValue.email){
      newErrors.email = 'This field is required';
      hasError = true;
    }
    if(!formValue.contact){
      newErrors.contact = 'This field is required';
      hasError = true;
    }
    if(!formValue.address1){
      newErrors.address1 = 'This field is required';
      hasError = true;
    }
    if(!formValue.city){
      newErrors.city = 'This field is required';
      hasError = true;
    }
    if(!formValue.state){
      newErrors.state = 'This field is required';
      hasError = true;
    }
    if(!formValue.pin){
      newErrors.pin = 'This field is required';
      hasError = true;
    }
    if(!formValue.dob){
      newErrors.dob = 'This field is required';
      hasError = true;
    }

    if(!formValue.status){
      newErrors.status = 'This field is required';
      hasError = true;
    }
    
    if(married !== 'single'){
      if(differentdiscount === 'yes'){
        if(!formValue.spouseEmail){
          newErrors.spouseEmail = 'This field is required';
          hasError = true;
        }
      }
      if(!formValue.spousefirstName){
          newErrors.spousefirstName = 'This field is required';
          hasError = true;
      }
      if(!formValue.spouselastName){
          newErrors.spouselastName = 'This field is required';
          hasError = true;
      }
    }
    
    if(hasError){
      setError(newErrors);
      setLoading(false)
    }else{
      const formData = new FormData();
      if(customerid){
        formData.append('id', customerid);
      }
      formData.append('customer_id', localStorage.getItem('authenticateid'));
      formData.append('resident_age', readio === 'option1' ? 'yes' : 'no')
      formData.append('title', formValue.title)
      formData.append('first_name', formValue.firstName)
      formData.append('middle_name', formValue.midName)
      formData.append('last_name', formValue.lastName)
      formData.append('different_name', differentname)
      formData.append('different_first_name', formValue.otherFirstName)
      formData.append('different_middle_name', formValue.othermidName)
      formData.append('different_last_name', formValue.otherlastName)
      formData.append('email_address', formValue.email)
      formData.append('phone_code', formValue.phoneCode)
      formData.append('contact_no', formValue.contact)
      formData.append('address1', formValue.address1)
      formData.append('address2', formValue.address2)
      formData.append('city', formValue.city)
      formData.append('state', formValue.state)
      formData.append('zip', formValue.pin)
      formData.append('date_of_birth', formValue.dob)
      formData.append('marital_status',married)
      formData.append('spouse_couple_package', differentdiscount)
      formData.append('spouse_email_address', formValue.spouseEmail)
      formData.append('spouse_first_name', formValue.spousefirstName)
      formData.append('spouse_middle_name', formValue.spousemidName)
      formData.append('spouse_last_name', formValue.spouselastName)
      formData.append('do_you_children', children);
      try {
        const response = await axios.post(API_ENDPOINTS.PARSONALACTION, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200) {
          
          alert({
            text: response.data,
            type: 'success',
          });
          navigate('/executors-details')
        } else {
          alert({
            text: response.data,
            type: 'error',
          });
        }
        
      } catch (error) {
        alert({
          text: error,
          type: 'error',
        });
      } finally {
        setLoading(false)
      }

    }
    

    





  }

  return (
    <>
      <form action="" id="form-about1" onSubmit={handleMainSubmitForm}>
        <h2>About YourSelf</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="input-wrapper">
              <label for="">
                Are you above 18 years of age and Resident of Australia? *
              </label>
              <div>
                <input
                  type="radio"
                  id="radio-1"
                  name="age-resident"
                  value="option1"
                  checked={readio === "option1"}
                  onChange={headleAge}
                />
                <label className="tab" for="radio-1">
                  Yes
                </label>
                <input
                  type="radio"
                  id="radio-2"
                  name="age-resident"
                  value="option2"
                  checked={readio === "option2"}
                  onChange={headleAge}
                />
                <label className="tab" for="radio-2">
                  No
                </label>
              </div>
            </div>
            {readio === "option2" && (
              <div className="input-wrapper">
                <h5>
                  Will cannot be made if You are not residing in Australia and
                  above 18 years.
                </h5>
              </div>
            )}
          </div>
          <div className="col-lg-12">
            <div className="input-wrapper">
              <label for="">Titles*</label>
              <div>
                <select name="title" className="form-select" value={formValue.title} onChange={handleInputMainChange} id="">

                  <option value={'mr'}>
                    Mr.
                  </option>
                  <option value={'ms'}>Ms.</option>
                  <option value={'miss'}>Miss.</option>

                </select>
                {error.title && <p className="text-danger">{error.title}</p>}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <label for="">First Name*</label>
              <input type="text" name="firstName" value={formValue.firstName} onChange={handleInputMainChange} className="form-control" placeholder="First" />
              {error.firstName && <p className="text-danger">{error.firstName}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <label for="">Middle Name </label>
              <input
                type="text"
                className="form-control"
                placeholder="Middle"
                name="midName"
                value={formValue.midName}
                onChange={handleInputMainChange}
              />
              {error.midName && <p className="text-danger">{error.midName}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <label for="">Last Name*</label>
              <input type="text" className="form-control" placeholder="Last"
                name="lastName"
                value={formValue.lastName}
                onChange={handleInputMainChange}
              />
              {error.lastName && <p className="text-danger">{error.lastName}</p>}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-wrapper">
              <label for="">
                Have you ever been known with a different name or have Assets in
                any other name/s or different spelling? *
              </label>
              <div>
                <input
                  type="radio"
                  id="radio-3"
                  name="different-name"
                  value="yes"
                  checked={differentname === "yes"}
                  onChange={headlename}
                />
                <label className="tab" for="radio-3">
                  Yes
                </label>
                <input
                  type="radio"
                  id="radio-4"
                  name="different-name"
                  value="no"
                  checked={differentname === "no"}
                  onChange={headlename}
                />
                <label className="tab" for="radio-4">
                  No
                </label>
              </div>
            </div>

            {differentname === "yes" && (
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <label for="">
                      First / Middle / Last name (Full Name) (other name if
                      applicable) *
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First"
                        name="otherFirstName"
                        value={formValue.otherFirstName}
                        onChange={handleInputMainChange}
                      />
                      {error.otherFirstName && <p className="text-danger">{error.otherFirstName}</p>}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Middle"
                        name="othermidName"
                        value={formValue.othermidName}
                        onChange={handleInputMainChange}
                      />
                      
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last"
                        name="otherlastName"
                        value={formValue.otherlastName}
                        onChange={handleInputMainChange}
                      />
                      {error.otherlastName && <p className="text-danger">{error.otherlastName}</p>}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <div className="input-wrapper">
              <label for="">Email*</label>
              <input
                type="email"
                name="email"
                id=""
                className="form-control"
                placeholder="Enter Your Email"
                value={formValue.email}
                onChange={handleInputMainChange}
              />
              {error.email && <p className="text-danger">{error.email}</p>}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-wrapper country-list-wrapper">
              <label for="">Phone*</label>
              <select className="country-selection" defaultValue={formValue.phoneCode} name="phoneCode" onChange={handleInputMainChange}>
                <option value="93">India (+91)</option>
                <option value="355">Albania (+355)</option>
                <option value="213">Algeria (+213)</option>
                <option value="1684">American Samoa (+1684)</option>
                <option value="376">Andorra (+376)</option>
                <option value="244">Angola (+244)</option>
                <option value="1264">Anguilla (+1264)</option>
              </select>
              <input
                type="text"
                name="contact"
                id=""
                className="form-control"
                placeholder=""
                value={formValue.contact}
                onChange={handleInputMainChange}
              />
              {error.contact && <p className="text-danger">{error.contact}</p>}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-wrapper">
              <label for="">What's your current residential address?*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address Line 1"
                name="address1"
                value={formValue.address1}
                onChange={handleInputMainChange}
              />
              {error.address1 && <p className="text-danger">{error.address1}</p>}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Address Line 2"
                name="address2"
                value={formValue.address2}
                onChange={handleInputMainChange}
              />
              {error.address2 && <p className="text-danger">{error.address2}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input type="text" className="form-control" placeholder="City"
                name="city"
                value={formValue.city}
                onChange={handleInputMainChange}
              />
              {error.city && <p className="text-danger">{error.city}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="State/Province/Region"
                name="state"
                value={formValue.state}
                onChange={handleInputMainChange}
              />
              {error.state && <p className="text-danger">{error.state}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Postal Code"
                name="pin"
                value={formValue.pin}
                onChange={handleInputMainChange}
              />
              {error.pin && <p className="text-danger">{error.pin}</p>}
            </div>
          </div>
          <div className="col-12">
            <div className="input-wrapper">
              <label for="">What is your date of birth? *</label>
              <input
                type="date"
                name="dob"
                id=""
                className="form-control"
                value={formValue.dob}
                onChange={handleInputMainChange}
              />
              {error.dob && <p className="text-danger">{error.dob}</p>}
            </div>
          </div>
          <div className="col-12">
            <div className="input-wrapper">
              <label for="">Current Marital status *</label>
              <select name="status" id="" value={married} onChange={handleMarried} className="form-select">
                <option value={''}>Select</option>
                <option value={'single'}>Single</option>
                <option value={'married'}>Married</option>
                <option value={'divorced'}>Divorced</option>
                <option value={'widowed'}>Widowed</option>
                <option value={'separated'}>Separated</option>
              </select>
            </div>
            {error.status && <p className="text-danger">{error.status}</p>}
          </div>
          {married !== 'single' && 
            <>
            <div className="col-12">
            <div className="input-wrapper">
              <label for="">
                Do you want to have your Spouse Will created as a Couple package
                ( Heavily discounted) *
              </label>
              <div>
                <input
                  type="radio"
                  id="radio-5"
                  name="differentdiscount"
                  value="yes"
                  checked={differentdiscount === "yes"}
                  onChange={headlediscount}
                />
                <label className="tab" for="radio-5">
                  Yes
                </label>
                <input
                  type="radio"
                  id="radio-6"
                  name="differentdiscount"
                  value="no"
                  checked={differentdiscount === "no"}
                  onChange={headlediscount}
                />
                <label className="tab" for="radio-6">
                  No
                </label>
              </div>
            </div>
            {differentdiscount === "yes" && (
              <div className="input-wrapper">
                <label for="">Enter email address of spouse*</label>
                <input
                  type="email"
                  name="spouseEmail"
                  id=""
                  className="form-control"
                  placeholder="Email Address"
                  value={formValue.spouseEmail}
                  onChange={handleInputMainChange}
                />
            {error.spouseEmail && <p className="text-danger">{error.spouseEmail}</p>}
              </div>
            )}
          </div>
          <div className="col-12">
            <div>
              <small className="fst-italic primary-text">
                *You have a partner if you’re married or have a de
                facto/domestic relationship.
              </small>
            </div>
            <label for="">Enter your spouse Full name*</label>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input type="text" placeholder="First" className="form-control"
                value={formValue.spousefirstName}
                name="spousefirstName"
                onChange={handleInputMainChange}
              />
              {error.spousefirstName && <p className="text-danger">{error.spousefirstName}</p>}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Middle"
                className="form-control"
                name="spousemidName"
                value={formValue.spousemidName}
                onChange={handleInputMainChange}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="input-wrapper">
              <input type="text" placeholder="Last" className="form-control"
                value={formValue.spouselastName}
                name="spouselastName"
                onChange={handleInputMainChange}

              />
              {error.spouselastName && <p className="text-danger">{error.spouselastName}</p>}
            </div>
          </div>
            </>
          }
          
          <div className="col-12">
            <div className="input-wrapper">
              <label for="">Do you have Children? *</label>
              <div>
                <input
                  type="radio"
                  id="radio-7"
                  name="children"
                  value="yes"
                  checked={children === "yes"}
                  onChange={headlechildren}
                />
                <label className="tab" for="radio-7">
                  Yes
                </label>
                <input
                  type="radio"
                  id="radio-8"
                  name="children"
                  value="no"
                  checked={children === "no"}
                  onChange={headlechildren}
                />
                <label className="tab" for="radio-8">
                  No
                </label>
              </div>
            </div>
            {children === "yes" && (
              <>
                {members.length > 0 && (
                  <>
                    <h5>My Children Are...</h5>

                    {members.map((member, index) => (
                      <div key={member.id} style={{ backgroundColor: 'white', padding: '10px', marginBottom: '10px', justifyContent: 'space-between', alignItems: 'center' }} className="d-flex p-3 border rounded">

                        <div className="rounded-circle d-flex justify-content-center" style={{ backgroundColor: colorMapping[member.first_name[0].toUpperCase()], width: '50px', height: '50px', padding: '10px' }}>{member.first_name[0].toUpperCase()}</div>

                        <div>{member.first_name + " " + member.middle_name + " " + member.last_name} <br />
                          <button type="button" style={{ paddingLeft: '4px', paddingRight: '4px', border: 'none', borderRadius: '2px', fontSize: '10px' }}>{member.relation}</button>
                        </div>
                        <div><span onClick={() => handleDelete(member.id)} style={{ cursor: 'pointer' }}><i class="fa-solid fa-circle-minus"></i> Remove</span></div>
                        <div><span style={{ cursor: 'pointer' }} onClick={() => handleEdit(member.id)}><i class="fa-solid fa-pen"></i> Edit</span></div>
                      </div>
                    ))}

                  </>
                )}
                <div className="new-child hidden-option-4">
                  <div className="add-child">
                    <p className="mb-0">None Selected</p>
                    <a
                      href="javascript:void(0)"
                      className="button-style1"
                      data-bs-toggle="modal"
                      data-bs-target="#add-child"
                      id="open-modal-button"
                      onClick={handleOpenModal}
                    >
                      <i className="fa-solid fa-plus"></i> Add Child
                    </a>
                  </div>

                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="add-child"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">
                            Add a Child
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="close-modal-button"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <h5>What is this child's name?</h5>
                          <p>
                            Please provide the individual’s full legal name to
                            ensure they are easily identifiable
                          </p>

                          <div className="row">
                            <div className="col-lg-4 col-md-6">
                              <div className="input-wrapper">
                                <label for="">First Name*</label>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  name="firstName"
                                  className="form-control"
                                  value={family.firstName}
                                  onChange={handleInputChange}
                                />
                                {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input-wrapper">
                                <label for="">Middle Name</label>
                                <input
                                  type="text"
                                  placeholder="Middle Name"
                                  name="midelName"
                                  className="form-control"
                                  value={family.midelName}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input-wrapper">
                                <label for="">Last Name*</label>
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  name="lastName"
                                  className="form-control"
                                  value={family.lastName}
                                  onChange={handleInputChange}
                                />
                                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                              </div>
                            </div>
                          </div>
                          <h5>How is the contact related to you?</h5>
                          <div className="row">
                            <div className="col-12">
                              <div className="input-wrapper">
                                <label for="">Relationship *</label>
                                <select
                                  name="relation"
                                  id=""
                                  className="form-select"
                                  value={family.relation}
                                  onChange={handleInputChange}
                                >
                                  <option value={''}> -------- Select Relation ----------- </option>
                                  <option value={'Child'}>Child</option>
                                  <option value={'Parent'}>Parent</option>
                                  <option value={'Grandparent'}>Grandparent</option>
                                  <option value={'Aunt/Uncle'}>Aunt/Uncle</option>
                                  <option value={'Cousin'}>Cousin</option>
                                  <option value={'Brother/Sister'}>
                                    Brother/Sister
                                  </option>
                                  <option value={'Other'}>Other</option>
                                </select>
                                {errors.relation && <div className="text-danger">{errors.relation}</div>}
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="form-check align-content-center">
                                <input
                                  className="form-check-input mt-4"
                                  type="checkbox"
                                  value=""
                                  id="joinwill"
                                  checked={isVisible.joinwill}
                                  onChange={handleToggle}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  <h6>Invite this contact to join Safewill</h6>
                                  <p>
                                    They must be over the age of 18 and a valid
                                    email address must be provided
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="input-wrapper">
                                <h5>
                                  Method of verification{" "}
                                  <span>Choose at least 1</span>
                                </h5>
                                <p>
                                  Identification methods are used by your Executor
                                  to identify people in your Will
                                </p>
                                <div className="method-wrapper">
                                  <div className="item">
                                    <div className="form-check form-switch">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="dob"
                                          checked={isVisible.dob}
                                          onChange={handleToggle}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="dob"
                                        >
                                          Date of Birth
                                        </label>
                                      </div>

                                      {isVisible.dob && (
                                        <div className="other">
                                          <div className="input-wrapper">
                                            <label for="">Select Date*</label>
                                            <input
                                              type="date"
                                              name="dob"
                                              id=""
                                              className="form-control"
                                              value={family.dob}
                                              onChange={handleInputChange}
                                            />
                                            {errors.dob && <div className="text-danger">{errors.dob}</div>}

                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="form-check form-switch">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="phone"
                                          checked={isVisible.phone}
                                          onChange={handleToggle}
                                        />

                                        <label
                                          className="form-check-label"
                                          for="phone"
                                        >
                                          Phone Number
                                        </label>
                                      </div>
                                      {isVisible.phone && (
                                        <div className="other">
                                          <div className="row">
                                            <div className="col-lg-4">
                                              <div className="input-wrapper">
                                                <label for="">Area Code *</label>
                                                <select
                                                  name="area_code"
                                                  className="form-select"
                                                  id=""
                                                  value={family.area_code}
                                                  onChange={handleInputChange}
                                                >
                                                  <option value="93">India (+91)</option>
                                                  <option value="355">Albania (+355)</option>
                                                  <option value="213">Algeria (+213)</option>
                                                  <option value="1684">American Samoa (+1684)</option>
                                                  <option value="376">Andorra (+376)</option>
                                                  <option value="244">Angola (+244)</option>
                                                  <option value="1264">Anguilla (+1264)</option>
                                                </select>
                                                {errors.area_code && <div className="text-danger">{errors.area_code}</div>}

                                              </div>
                                            </div>
                                            <div className="col-lg-8">
                                              <div className="input-wrapper">
                                                <label for="">
                                                  Phone Number *
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="contact"
                                                  placeholder="0000 000 000"
                                                  value={family.contact}
                                                  onChange={handleInputChange}
                                                />
                                                {errors.contact && <div className="text-danger">{errors.contact}</div>}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="form-check form-switch input-wrapper">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="email"
                                          checked={isVisible.email}
                                          onChange={handleToggle}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="email"
                                        >
                                          Email Address
                                        </label>
                                      </div>
                                      {isVisible.email && (
                                        <div className="other">
                                          <label for="">Email Address *</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="email123@gmail.com"
                                            value={family.email}
                                            onChange={handleInputChange}
                                          />
                                          {errors.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="form-check form-switch">
                                      <div>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="residential"
                                          checked={isVisible.residential}
                                          onChange={handleToggle}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="residential"
                                        >
                                          Residential Address
                                        </label>
                                      </div>
                                      {isVisible.residential && (
                                        <div className="other">
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                <label for="">Street *</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="street"
                                                  placeholder="Street"
                                                  value={family.street}
                                                  onChange={handleInputChange}
                                                />
                                                {errors.street && <div className="text-danger">{errors.street}</div>}
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                <label for="">Suburb *</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="suburb"
                                                  placeholder="0000 000 000"
                                                  value={family.suburb}
                                                  onChange={handleInputChange}
                                                />
                                                {errors.suburb && <div className="text-danger">{errors.suburb}</div>}
                                              </div>
                                            </div>

                                            <div className="col-12">
                                              <div className="input-wrapper">
                                                <label for="">Country *</label>
                                                <select
                                                  name="country"
                                                  className="form-select"
                                                  id=""
                                                  value={family.country}
                                                  onChange={handleInputChange}
                                                >
                                                  <option value=""> -- Select Country --
                                                  </option>
                                                  {countries.map((country, index) => (
                                                    <option value={country.id}> {country.name}</option>
                                                  ))}
                                                </select>
                                                {errors.country && <div className="text-danger">{errors.country}</div>}

                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                <label for="">State *</label>
                                                <select
                                                  name="state"
                                                  id=""
                                                  className="form-select"
                                                  value={family.state}
                                                  onChange={handleInputChange}
                                                >
                                                  <option value=""> ---- Select State ----</option>
                                                  {states.map((state, index) => (
                                                    <option value={state.id}>{state.name}</option>
                                                  ))}
                                                </select>
                                                {errors.state && <div className="text-danger">{errors.state}</div>}

                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="input-wrapper">
                                                <label for="">Postcode *</label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="postcode"
                                                  placeholder="Postcode"
                                                  value={family.postcode}
                                                  onChange={handleInputChange}
                                                />
                                                {errors.postcode && <div className="text-danger">{errors.postcode}</div>}

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="input-wrapper">
                                <label for="">
                                  Is this contact under the age of 18?
                                </label>
                                <div>
                                  <input
                                    type="radio"
                                    id="radio-12"
                                    name="tabs"
                                    value="yes"
                                    checked={readiounder === "yes"}
                                    onChange={handleunderage}
                                  />
                                  <label className="tab" for="radio-12">
                                    Under 18
                                  </label>
                                  <input
                                    type="radio"
                                    id="radio-13"
                                    name="tabs"
                                    value="no"
                                    checked={readiounder === "no"}
                                    onChange={handleunderage}
                                  />
                                  <label className="tab" for="radio-13">
                                    Over 18
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {update === '' ? (
                            <span
                              className="button-style1"
                              style={{ cursor: "pointer" }}
                              onClick={addFamilyMember}
                            >
                              Add
                            </span>
                          ) : (
                            <span
                              className="button-style1"
                              style={{ cursor: "pointer" }}
                              onClick={updateFamilyMember}
                            >
                              Update
                            </span>
                          )}

                          <span
                            className="button-style1"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <button className="button-style3" disabled={loading}>
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default Dashboard;
