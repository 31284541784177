import React from 'react';

const TimeDisplay = ({ timeFromDatabase }) => {
  const formatTime = (time) => {
    if (!time) return ''; // Check if time is undefined or null
    const [hours, minutes, seconds] = time.split(':');
    const hoursInt = parseInt(hours, 10);
    const ampm = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = hoursInt % 12 || 12; // Convert hour '0' to '12'
    const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
    return formattedTime;
  };

  return (
    <>
      <span>{formatTime(timeFromDatabase)}</span>
    </>
  );
};

export default TimeDisplay;
